import React, { useState } from "react"
import { getMissionStatus, isMissionComplete } from "../utils/utils"

import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import differenceInCalendarWeeks from "date-fns/differenceInCalendarWeeks"
import { toast } from "react-toastify"
import differenceInMonths from "date-fns/differenceInMonths"
import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import differenceInDays from "date-fns/differenceInDays"
import apiPlanItems from "../services/planItems/planItems.api"
import { eachDayOfInterval } from "date-fns/eachDayOfInterval"
import isLastDayOfMonth from "date-fns/isLastDayOfMonth"
import isFirstDayOfMonth from "date-fns/isFirstDayOfMonth"
import eachWeekOfInterval from "date-fns/eachWeekOfInterval"
import endOfMonth from "date-fns/endOfMonth"
import { endOfDay } from "date-fns/endOfDay"
import subWeeks from "date-fns/subWeeks"
import startOfDay from "date-fns/startOfDay"
import isSameWeek from "date-fns/isSameWeek"
import { eachMonthOfInterval } from "date-fns/eachMonthOfInterval"
import { isThisWeek } from "date-fns/isThisWeek"
import { isSaturday } from "date-fns/isSaturday"
import { startOfMonth } from "date-fns/startOfMonth"
import addMonths from "date-fns/addMonths"

import getISODay from "date-fns/getISODay"
import { isAfter } from "date-fns/isAfter"
import { isBefore } from "date-fns/isBefore"
import { isSameDay } from "date-fns/isSameDay"
import { startOfISOWeek } from "date-fns/startOfISOWeek"

import addDays from "date-fns/addDays"

import addMilliseconds from "date-fns/addMilliseconds"
import subDays from "date-fns/subDays"

import numeral from "numeral"
import addWeeks from "date-fns/addWeeks"

import isMonday from "date-fns/isMonday"
import isFriday from "date-fns/isFriday"

import isSunday from "date-fns/isSunday"
import SearchDropDown from "./SearchDropDown"
import endOfWeek from "date-fns/endOfWeek"
import differenceInWeeks from "date-fns/differenceInWeeks"

import startOfWeek from "date-fns/startOfWeek"
import { isWithinInterval } from "date-fns/isWithinInterval"
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping"

import apiActionItem from "../services/actionItem/actionItem.api"

import { getRole } from "./OrgGetRole"
import uniqBy from "lodash/uniqBy"

import intersection from "lodash/intersection"

import { toZonedTime } from "date-fns-tz"

import { getAiEndDate, getTimesheetHours, getTimeEntryHours, getAiDueDate } from "../comp/DateUtils"
import { getTimeUtc } from "../utils/dates"
import get from "lodash/get"
import flatMap from "lodash/flatMap"
import isFinite from "lodash/isFinite"
import groupBy from "lodash/groupBy"
import orderBy from "lodash/orderBy"
import intersectionBy from "lodash/intersectionBy"
import omit from "lodash/omit"
import { getObjectId, getRefId, millisToHours, listToTree } from "./Utils"

import { MISSION_PERMISSION, MISSION_PERMISSION_LABEL_MAP } from "../services/mission/mission.const"
import { ORG_PERMISSION } from "../services/org/org.const"
import { getMissionPromoByType, getMissionSubscriptionPlanItemByType } from "./StripeUtils"
import { v4 as uniqid } from "uuid"

import { isSameMonth } from "date-fns"
import { GT_CUSTOM_ROLE_LABELS, GT_CUSTOM_ROLES } from "../services/constants.service"

const defaultTags = ["OpEx", "CapEx", "Fixed expense", "Variable expense", "$#!* happens", "Discretionary"]

export { defaultTags }

function getMissionDepartmentTagsAsText(org, tags = []) {
    const depMap = Object.fromEntries((org.departments || []).map((item) => [item._id, item]))

    if (Object.keys(depMap).length === 0) {
        return ""
    }

    return (tags || [])
        .map((t) => {
            return depMap[t]
        })
        .filter((d) => !!d)
        .join(", ")
}

export function sectionPercentComplete({ mission, sectionTag }) {
    const allActionItems = getAllActionItems(mission).filter((ai) => ai.sectionTagId === sectionTag._id)
    let totalHours = 0
    let totalDone = 0

    if (mission.projectType === "simple") {
        allActionItems.forEach((ai, i) => {
            if (ai.status === "done") {
                totalDone += ai.estimatedDuration
            }
            totalHours += ai.estimatedDuration
        })

        return totalHours === 0 ? 0 : (totalDone / totalHours) * 100
    }
    if (mission.projectType === "kanban") {
        allActionItems.forEach((ai, i) => {
            if (ai.status === "done") {
                totalDone++
            }
        })

        return allActionItems.length === 0 ? 0 : (totalDone / allActionItems.length) * 100
    }
}

export function ensureNumber(val) {
    let newVal = val
    if (newVal === null) return 0
    if (newVal === undefined) return 0
    if (typeof newVal === "string") {
        newVal = newVal.replaceAll(",", "")
    }
    if (Number.isNaN(+newVal)) return 0
    return +newVal
}

export function showBillableFields(mission) {
    let show = false

    if (existsTimeTracking(mission)) {
        show = Boolean(
            mission.client || mission.orderCode || mission.isModel || mission.isTemplate || mission.showBillables
        )
    }

    return show && !mission.zeroBill && !mission.notBillable && !mission.billUsingMilestones
}

export function showBillableFieldsOnTasks(mission) {
    if (!existsTimeTracking(mission)) return false

    if (
        mission.projectType === "classic-gantt" &&
        !mission.billUsingMilestones &&
        !mission.zeroBill &&
        !mission.notBillable &&
        mission.client
    ) {
        return true
    }

    if (mission.projectType === "kanban" || mission.projectType === "simple") {
        return !mission.notBillable && mission.advancedBudgetting && !mission.billUsingMilestones
    }
}

export function getMxMilestoneTotals(mission) {
    const milestones = mission.planItems
        .filter((p) => p.type === "milestone")
        .sort((a, b) => {
            return a.startDate - b.startDate
        })
    let total = 0
    milestones.forEach((m, i) => {
        total += m.amount || 0
    })

    return total
}

export function addChecklistIdsFixGap(allActionItems, app) {
    const checklistUpdates = []

    allActionItems.forEach((ai, i) => {
        const updateNeeded = ai.checklist?.filter((c) => !c.id)
        updateNeeded.forEach((cl, i) => {
            checklistUpdates.push({
                ai: ai,
                checklistItem: cl,
                change: {
                    id: uniqid(),
                },
            })
        })
    })
    if (checklistUpdates.length) {
        app.checklistItemsBulkUpdateAcrossAis(checklistUpdates)
    }
}

export function getMyDependencies(obj, allActionItems) {
    if (obj?.actionId) {
        return obj?.dependencies?.filter((d) => d.linkType === "dependsOn") || []
    } else if (obj && allActionItems) {
        const inventoryOfAllChecklistItems = flatMap(allActionItems, "checklist")

        return (obj.dependsOn || [])
            .map((d) => {
                const foundAi = allActionItems.find((ai) => ai._id === d.depId)
                const foundCl = inventoryOfAllChecklistItems.find((c) => c.id === d.depId)

                const cObj = foundCl || foundAi

                if (!cObj) return null

                return {
                    linkTo: cObj,
                    linkType: "dependsOn",
                }
            })
            .filter((d) => !!d)
    } else {
        return []
    }
}

export function getItemsDependingOnMe(obj, allActionItems) {
    if (obj?.actionId) {
        return allActionItems.filter((ai) =>
            ai.dependencies?.find((d) => d.linkType === "dependsOn" && d.linkTo._id === obj._id)
        )
    }
}

export function makeMissionNotBillable(mission, app, cb) {
    app.confirm({
        severe: true,
        yesText: "Make project NOT billable",
        comp: (
            <>
                <p>Don't track billing and revenue? </p>
                <p>You will still be able to track costs and expenditures.</p>
            </>
        ),
        onYes: () => {
            app.missionUpdate(mission._id, {
                notBillable: true,
                //advancedBudgetting: false,
                //billUsingMilestones: false,
                //showBillables: false,
            })

            setTimeout(() => {
                if (cb) cb()
            }, 500)
            /*let changeArray = []
            mission.planItems
                .filter((p) => p.billRate)
                .forEach((p, i) => {
                    changeArray.push({
                        obj: p,
                        change: {
                            _id: p._id,
                            billRate: 0,
                            amount: 0,
                        },
                    })
                })

            const allActionItems = getAllActionItems(mission)

            let aiChanges = []
            let checklistChanges = []

            allActionItems.forEach((ai, i) => {
                if (ai.billRate) {
                    aiChanges.push({
                        obj: ai,
                        change: {
                            _id: ai._id,
                            billRate: null,
                        },
                    })
                }

                ai.checklist?.forEach((c, i) => {
                    if (c.billRate) {
                        checklistChanges.push({
                            ai: ai,
                            checklistItem: c,
                            change: {
                                _id: c._id,
                                billRate: null,
                            },
                        })
                    }
                })
            })

            if (changeArray.length) {
                setTimeout(() => {
                    app.planItemUpdateBulk(mission._id, changeArray)
                }, 30)
            }

            if (aiChanges.length) {
                setTimeout(() => {
                    app.actionItemUpdateBulk(mission._id, aiChanges)
                }, 50)
            }

            if (checklistChanges.length) {
                setTimeout(() => {
                    app.checklistItemsBulkUpdateAcrossAis(checklistChanges)
                }, 80)
            }*/
        },
    })

    //getAllAction
}

export function makeMissionBillable(mission, app, org, cb) {
    app.confirm({
        comp: (
            <>
                <p>Great! Please read this 👇</p>
                <p>
                    I will activate any existing revenue items and recalculate any estimated revenues and costs for you.
                </p>
                <p>Ok to proceed?</p>
            </>
        ),
        width: 550,
        yesText: "Yes, Make project billable",
        onYes: () => {
            //const allActionItems = getAllActionItems(mission)

            app.missionUpdate(mission._id, {
                notBillable: false,
            })

            setTimeout(() => {
                if (cb) cb()
            }, 500)
        },
    })
}

export function getMissionActions(mission) {
    return flatMap((get(mission, "planItems") || []).map((planItem) => planItem.actions))
}

export function getAllActionItems(mission) {
    if (!mission || !mission?.planItems?.length || !mission.planItems[0].actions) {
        return []
    }

    return mission.planItems
        .flatMap((p) => p.actions)
        .flatMap((a) => a.actionItems)
        .filter((ai) => !!ai)
}

export function getAllActions(mission) {
    let actions = flatMap(mission.planItems, "actions")

    return actions
}

export function hasAdvancedBilling(mission) {
    if (!mission) return false
    if (mission.isProposal) return false
    if (mission.notBillable) return false
    if (mission.billUsingMilestones) return false
    return Boolean(mission.advancedBudgetting || mission.projectType === "classic-gantt")
}

export function getMissionMinEndDate(mission, timesheets = []) {
    const actions = getAllActions(mission)
    const actionItems = getAllActionItems(mission).filter((ai) => ai.status === "done")
    const maxAi = actionItems.sort((a, b) => {
        const dateToUseA = a.startDate || a.completedOn
        const dateToUseB = b.startDate || b.completedOn
        return dateToUseB - dateToUseA
    })[0]

    let maxAiDate = maxAi?.startDate || maxAi?.completedOn

    if (!maxAi?.startDate && maxAi?.completedOn) {
        const myAction = actions.find((a) => a._id === maxAi.actionId)
        if (myAction) maxAiDate = myAction.startDate
    }

    if (maxAiDate) {
        const diffInDays = differenceInCalendarDays(toZonedTime(maxAiDate), toZonedTime(mission.planStartDate))
        if (diffInDays < 5) {
            maxAiDate = getTimeUtc(addDays(toZonedTime(maxAiDate), 5))
        }
    }

    if (!existsTimeTracking(mission)) {
        if (maxAiDate) {
            return endOfDay(toZonedTime(maxAiDate))
        }
    } else {
        const maxTimesheetDate = timesheets
            .filter((ts) => ts.status === "approved")
            .sort((a, b) => a.weekStart - b.weekStart)[0]?.weekStart

        if (maxAiDate && maxTimesheetDate) {
            return maxAiDate < maxTimesheetDate ? toZonedTime(maxAiDate) : toZonedTime(maxTimesheetDate)
        } else if (maxAiDate) {
            return toZonedTime(maxAiDate)
        } else if (maxTimesheetDate) {
            return toZonedTime(maxTimesheetDate)
        }
    }

    return null
}

export function getStartAndEndDateOfAllMissions(missions) {
    let s = 9999999999999999999
    let e = 0

    if (missions.length === 0) {
        return {
            startDate: startOfMonth(new Date()),
            endDate: endOfMonth(addMonths(new Date(), 12)),
        }
    }

    missions.forEach((mission, i) => {
        if (mission.planStartDate && mission.planEndDate) {
            s = s > mission.planStartDate ? mission.planStartDate : s
            e = e < mission.planEndDate ? mission.planEndDate : e
        } else if (mission.planItems && mission.planItems.length) {
            const d = getMissionStartEndBasedOnPlanItems(mission.planItems)

            s = s > getTimeUtc(d.startDate) ? getTimeUtc(d.startDate) : s
            e = e < getTimeUtc(d.endDate) ? getTimeUtc(d.endDate) : e
        }
    })

    return {
        startDate: toZonedTime(s),
        endDate: toZonedTime(e),
    }
}

export function getMissionMaxStartDate(mission, timesheets = []) {
    const actions = getAllActions(mission)
    const actionItems = getAllActionItems(mission).filter((ai) => ai.status === "done")
    const maxAi = actionItems.sort((a, b) => {
        const dateToUseA = a.startDate || a.completedOn
        const dateToUseB = b.startDate || b.completedOn
        return dateToUseA - dateToUseB
    })[0]

    let maxAiDate = maxAi?.startDate || maxAi?.completedOn

    if (!maxAi?.startDate && maxAi?.completedOn) {
        const myAction = actions.find((a) => a._id === maxAi.actionId)
        if (myAction) maxAiDate = myAction.startDate
    }

    if (!existsTimeTracking(mission)) {
        if (maxAiDate) return toZonedTime(maxAiDate)
    } else {
        const maxTimesheetDate = timesheets
            .filter((ts) => ts.status === "approved")
            .sort((a, b) => a.weekStart - b.weekStart)[0]?.weekStart

        if (maxAiDate && maxTimesheetDate) {
            return maxAiDate < maxTimesheetDate ? toZonedTime(maxAiDate) : toZonedTime(maxTimesheetDate)
        } else if (maxAiDate) {
            return toZonedTime(maxAiDate)
        } else if (maxTimesheetDate) {
            return toZonedTime(maxTimesheetDate)
        }
    }

    if (mission.missionStop) {
        return subDays(toZonedTime(mission.missionStop), 5)
    }

    return null
}

export function getActionRollUp(action, actions) {
    const myActions = []
    const treeActions = listToTree(JSON.parse(JSON.stringify(actions)))

    function searchTree(element, id) {
        if (element._id == id) {
            return element
        } else if (element.children != null) {
            var i
            var result = null
            for (i = 0; result == null && i < element.children.length; i++) {
                result = searchTree(element.children[i], id)
            }
            return result
        }
        return null
    }

    function walk(a) {
        if (!a) return
        if (a.children !== undefined) {
            a.children
                .filter((c) => !!c)
                .forEach(function (child) {
                    walk(child)
                    myActions.push(child)
                })
        }

        myActions.push(a)
    }

    const treeNode = searchTree({ children: treeActions }, action._id)

    walk(treeNode)

    return uniqBy(myActions, "_id").reverse()
}

export function shiftThePlan(mission, app, zeDate, cb) {
    const finishUp = (msChanges, actionChanges, actionItemChanges, res) => {
        if (actionChanges.length) {
            app.actionUpdateBulk(mission._id, actionChanges, true)
        }

        if (actionItemChanges.length) {
            setTimeout(() => {
                app.actionItemUpdateBulk(mission._id, actionItemChanges)
            }, 40)
        }

        if (msChanges.length) {
            setTimeout(() => {
                app.planItemUpdateBulk(mission._id, msChanges)
            }, 60)
        }

        if (cb) {
            cb(res)
        }
    }

    const planStart = toZonedTime(mission.planStartDate)
    const forw = isAfter(zeDate, planStart)

    const wsOn = { weekStartsOn: 1 }

    let weekChange = !isSameWeek(zeDate, planStart, { weekStartsOn: 1 })

    let numberOfWks = forw
        ? differenceInWeeks(zeDate, planStart, { weekStartsOn: 1 })
        : differenceInWeeks(planStart, zeDate, { weekStartsOn: 1 })

    if (weekChange && numberOfWks === 0) {
        numberOfWks = 1
    }

    //const changeDayFnToUse = forw ? addDays : subDays
    const changeWeekFnToUse = (d) => {
        let fne = forw ? addWeeks : subWeeks

        return fne(d, numberOfWks, wsOn)
    }

    let sectionTagChanges = (mission.sectionTags || []).slice()

    sectionTagChanges.forEach((st, i) => {
        const newSTD = startOfDay(changeWeekFnToUse(toZonedTime(sectionTagChanges[i].from)))
        const newEND = endOfDay(changeWeekFnToUse(toZonedTime(sectionTagChanges[i].to)))

        sectionTagChanges[i].from = getTimeUtc(newSTD)
        sectionTagChanges[i].to = getTimeUtc(newEND)
    })

    const nePEndDate = changeWeekFnToUse(toZonedTime(mission.planEndDate))

    app.missionUpdate(mission._id, {
        planStartDate: getTimeUtc(zeDate),
        planEndDate: getTimeUtc(nePEndDate),
        sectionTags: sectionTagChanges,
    })
        .then((res) => {
            app.unBlock()

            const allActions = getAllActions(mission)
            const allMilestones = mission.planItems.filter((p) => p.type === "milestone")
            let actionChanges = []
            let actionItemChanges = []
            let msChanges = []

            if (!weekChange) {
                const utcSDate = getTimeUtc(zeDate)

                allActions
                    .filter((a) => {
                        return (
                            a.status !== "done" &&
                            ((a.startDate && a.startDate < utcSDate) ||
                                (a.actionItems[0] && a.actionItems[0].startDate < utcSDate))
                        )
                    })
                    .forEach((a, i) => {
                        actionChanges.push({
                            _id: a._id,
                            startDate: utcSDate,
                        })

                        const myAi = a.actionItems[0]

                        if (myAi && myAi.startDate < utcSDate) {
                            let sth = myAi.startHour
                            let startInStartHour = ((zeDate.getDay() || 7) - 1) * 8

                            if (startInStartHour > sth) {
                                sth = startInStartHour
                            }

                            actionItemChanges.push({
                                obj: myAi,
                                change: {
                                    _id: myAi._id,
                                    startDate: utcSDate,
                                    startHour: sth,
                                },
                            })
                        }
                    })

                allMilestones
                    .filter((ms) => {
                        return ms.startDate < utcSDate
                    })
                    .forEach((ms, i) => {
                        const end = endOfDay(zeDate)
                        msChanges.push({
                            obj: ms,
                            change: {
                                _id: ms._id,
                                startDate: utcSDate,
                                endDate: getTimeUtc(end),
                            },
                        })
                    })

                finishUp(msChanges, actionChanges, actionItemChanges)
            } else {
                allActions
                    .filter((a) => a.startDate && a.status !== "done")
                    .forEach((a, i) => {
                        const acCurrentStartDate = toZonedTime(a.startDate)

                        const isASameWeekItem = isSameWeek(acCurrentStartDate, planStart, { weekStartsOn: 1 })

                        let newAcDate = isASameWeekItem ? zeDate : changeWeekFnToUse(toZonedTime(a.startDate))

                        if (isBefore(newAcDate, zeDate)) {
                            newAcDate = zeDate
                        }

                        let newAcEndDate = endOfWeek(toZonedTime(newAcDate), { weekStartsOn: 1 })

                        if (isAfter(newAcEndDate, nePEndDate)) {
                            newAcEndDate = nePEndDate
                        }

                        actionChanges.push({
                            _id: a._id,
                            startDate: getTimeUtc(newAcDate),
                            endDate: getTimeUtc(nePEndDate),
                        })

                        const myAi = a.actionItems[0]

                        if (myAi?.startDate && myAi.status !== "done") {
                            let newAiDate

                            if (mission.projectType === "kanban") {
                                newAiDate = newAcDate
                            } else {
                                newAiDate = changeWeekFnToUse(toZonedTime(myAi.startDate))
                            }

                            actionItemChanges.push({
                                obj: myAi,
                                change: {
                                    _id: myAi._id,
                                    startDate: getTimeUtc(startOfDay(newAiDate)),
                                    initialStartDate: null,
                                    dueDate: null,
                                    endDate: null,
                                },
                            })
                        }
                    })

                allMilestones.forEach((ms, i) => {
                    let newMsStartDate = changeWeekFnToUse(toZonedTime(ms.startDate))
                    if (isBefore(newMsStartDate, zeDate)) {
                        newMsStartDate = startOfDay(zeDate)
                    }
                    const newMsEndDate = endOfDay(newMsStartDate)

                    msChanges.push({
                        obj: ms,
                        change: {
                            _id: ms._id,
                            startDate: getTimeUtc(newMsStartDate),
                            endDate: getTimeUtc(newMsEndDate),
                        },
                    })
                })

                finishUp(msChanges, actionChanges, actionItemChanges, res)
            }
        })
        .catch((err) => {
            console.error(err)
            app.unBlock()
        })
}

export function TemplateDropDown({
    items,
    value,
    onSelect,
    inputClassName,
    className,
    style,
    placeholder,
    mission,
    placeHolder,
    orgData,
    noAlpha,
}) {
    const [internalValue, setInternalValue] = useState(value)

    return (
        <SearchDropDown
            style={style}
            wrapperClassName={className}
            data={items}
            value={value || internalValue || ""}
            labelKey="title"
            noAlpha={noAlpha}
            selectOnBlur={false}
            onSelect={(value, obj) => {
                setInternalValue(value)
                onSelect(obj)
            }}
            placeHolder={placeHolder || "Project template..."}
            showClearIcon={true}
            onClear={() => {
                setInternalValue(null)
                onSelect(null)
            }}
            replaceTemplate={true}
            template={(obj) => {
                const rootTemplate = obj
                const data = obj.template.data
                const temp = obj.template

                const roles = temp?.dataPlanItems?.filter((pi) => pi.type === "person").length
                const depTags = getMissionDepartmentTagsAsText(orgData, temp?.data.departmentTags || [])

                return (
                    <div className="dna-dd-item">
                        <div>
                            <strong>{rootTemplate.title}</strong>
                        </div>

                        {rootTemplate.description && <div style={{ marginBottom: 4 }}>{obj?.description}</div>}
                        {data.projectType && (
                            <div className="dna-light-text dna-smaller-text">
                                Type:{" "}
                                {data.isProposalTemplate
                                    ? "Proposal"
                                    : data.projectType === "mx-gantt"
                                    ? "MX-GANTT"
                                    : data.projectType === "kanban"
                                    ? "Modern Kanban"
                                    : data.projectType === "simple"
                                    ? "Simple"
                                    : ""}
                            </div>
                        )}
                        {data.budgetAvailable > 0 && (
                            <div className="dna-light-text dna-smaller-text">
                                Budget: {numeral(data.budgetAvailable).format("0,0")} {mission && mission.currency}
                            </div>
                        )}
                        {data?.departmentTags?.length > 0 && (
                            <div className="dna-light-text dna-smaller-text">Departments: {depTags}</div>
                        )}
                        {temp?.dataPlanItems?.length > 0 && (
                            <div className="dna-light-text dna-smaller-text">
                                {roles} role{roles === 1 ? "" : "s"}
                            </div>
                        )}
                        <div className="dna-light-text dna-smaller-text">
                            {rootTemplate.isPrivate ? "Personal template" : "Organization template"}
                        </div>
                    </div>
                )
            }}
        />
    )
}

export function getExpectedHours(mission, planItem, timesheets, from, to) {
    let expected = 0
    let approved = 0
    let submitted = 0

    if (!mission || !planItem) {
        return {
            expected: expected,
            submitted: submitted,
            approved: approved,
        }
    }
    const weeks = eachWeekOfInterval({ start: from, end: to }, { weekStartsOn: 1 })

    let piTimesheets = timesheets.filter(
        (ts) =>
            ts.planItemId === planItem._id &&
            isWithinInterval(toZonedTime(ts.weekStart), {
                start: from,
                end: to,
            })
    )

    if (planItem.hoursNeeded) {
        return {
            expected: planItem.hoursNeeded,
        }
    } else {
        weeks.forEach((week, i) => {
            const eow = endOfWeek(week, { weekStartsOn: 1 })

            /*const isSD = isSameDay
            const isA = isAfter
            const ut = toZonedTime
            const iswi = isWithinInterval*/

            const case1 = isBefore(toZonedTime(planItem.startDate), week) && isAfter(toZonedTime(planItem.endDate), eow)
            const case2 =
                isSameDay(toZonedTime(planItem.startDate), week) && isSameDay(toZonedTime(planItem.endDate), eow)

            const case3 =
                isSameDay(toZonedTime(planItem.startDate), week) && isAfter(toZonedTime(planItem.endDate), eow)
            const case4 =
                isSameDay(toZonedTime(planItem.endDate), eow) && isBefore(toZonedTime(planItem.startDate), week)

            const case5 =
                isWithinInterval(toZonedTime(planItem.startDate), { start: week, end: eow }) &&
                isAfter(toZonedTime(planItem.endDate), eow)

            const case6 =
                isWithinInterval(toZonedTime(planItem.endDate), { start: week, end: eow }) &&
                isBefore(toZonedTime(planItem.startDate), week)

            const thisWeeksTimesheet = piTimesheets.find((ts) =>
                isWithinInterval(toZonedTime(ts.weekStart), {
                    start: week,
                    end: eow,
                })
            )

            if (case1 || case2 || case3 || case4) {
                expected += mission.workHoursPerWeek * (planItem.allocation / 100) || 0

                if (thisWeeksTimesheet?.status === "approved") {
                    approved += thisWeeksTimesheet.hours
                } else if (thisWeeksTimesheet?.status === "submitted") {
                    submitted += thisWeeksTimesheet.hours
                }
            } else if (case5) {
                const diff = differenceInBusinessDays(eow, toZonedTime(planItem.startDate))

                expected = diff * mission.hoursPerDay || 0
                if (thisWeeksTimesheet?.status === "approved") {
                    approved += thisWeeksTimesheet.hours
                } else if (thisWeeksTimesheet?.status === "submitted") {
                    submitted += thisWeeksTimesheet.hours
                }
            } else if (case6) {
                const diff = differenceInBusinessDays(toZonedTime(planItem.endDate), week)

                expected = diff * mission.hoursPerDay || 0
                if (thisWeeksTimesheet?.status === "approved") {
                    approved += thisWeeksTimesheet.hours
                } else if (thisWeeksTimesheet?.status === "submitted") {
                    submitted += thisWeeksTimesheet.hours
                }
            }
        })
    }

    return {
        expected: expected,
        submitted: submitted,
        approved: approved,
    }
}

export function getUnsubmittedRoles(mission, org) {
    let roles = []
    mission.planItems.forEach((pi, i) => {
        if (org?.rolesNeedApproval && org?.canProjectManagersApproveRoles && pi.type === "person" && !pi.person) {
            roles.push(pi)
        } else if (
            pi.type === "person" &&
            !pi.person &&
            !pi.personLeftRole &&
            (!pi.requests ||
                !pi.requests.length ||
                (pi.requests?.length &&
                    pi.requests[pi.requests.length - 1]?.status !== "approved" &&
                    pi.requests[pi.requests.length - 1]?.status !== "pending_approval"))
        ) {
            roles.push(pi)
        }
    })

    return roles
}

export function getEligibleTaskAssignees(ai, action, mission, endDate, includeMissionObservers) {
    if (!ai || !mission || !mission.people || !mission.people.length || !action) {
        return []
    }

    if (!endDate) {
        endDate = getAiEndDate(ai, mission)
    }

    let startDate = toZonedTime(ai.startDate)

    if ((!endDate || !startDate) && action.startDate && action.endDate) {
        endDate = toZonedTime(action.endDate)
        startDate = toZonedTime(action.startDate)
    }

    if ((!endDate || !startDate) && (!action.startDate || !action.endDate)) {
        return mission.people.filter((p) => p.permission < (includeMissionObservers ? 4 : 3))
    }

    let eligiblePeople = [],
        notEligiblePeople = []

    mission.people.forEach((pers, i) => {
        const myRoleItem = mission.planItems.find((p) => p.person === getRefId(pers))

        let isEligible = false

        const whoToInclude = includeMissionObservers ? 4 : 3

        if (
            pers.permission < whoToInclude &&
            myRoleItem &&
            action &&
            action.startDate &&
            startDate &&
            myRoleItem.startDate
        ) {
            try {
                if (
                    myRoleItem &&
                    areIntervalsOverlapping(
                        {
                            start: startDate,
                            end: endDate,
                        },
                        {
                            start: toZonedTime(myRoleItem.startDate),
                            end: toZonedTime(myRoleItem.endDate),
                        }
                    )
                ) {
                    isEligible = true
                }
            } catch (e) {}
        }

        if (isEligible) {
            eligiblePeople.push(pers)
        } else if (pers.permission < whoToInclude) {
            notEligiblePeople.push({ ...pers, isOutSideRole: true })
        }
    })

    return [...eligiblePeople, ...notEligiblePeople]
}

export function removeOrgObserversFromPeople(people) {
    return people.filter((p) => p.permission === 0 || p.permission === 1 || p.permission === 2)
}

export function getProjectHoursFromTo(mission, from, to) {
    if (!from) {
        from = toZonedTime(mission.planStartDate)
    }
    if (!to) {
        to = toZonedTime(mission.planEndDate)
    }

    const allAi = getAllActionItems(mission)

    let totalHours = 0
    let completedHours = 0

    allAi.forEach((ai, i) => {
        if (
            !ai.startDate ||
            !from ||
            !to ||
            (isAfter(to, from) &&
                !isWithinInterval(toZonedTime(ai.startDate), {
                    start: from,
                    end: to,
                }))
        )
            return

        if (!ai.checklist?.length || !ai.checklist?.find((cl) => cl.duration)) {
            totalHours += ai.estimatedDuration
            if (ai.status === "done") {
                completedHours += ai.estimatedDuration
            }
        } else {
            let dur = 0
            let clDoneHours = 0
            ai.checklist.forEach((cl, i) => {
                if (ai.status === "done" && !cl.done) {
                    //Do  nothing
                } else {
                    dur += cl.duration || 0
                    if (cl.done) {
                        clDoneHours += dur
                    }
                }
            })

            if (dur < ai.estimatedDuration && clDoneHours < ai.estimatedDuration) {
                totalHours += ai.estimatedDuration || 0
                if (ai.status === "done") {
                    completedHours += ai.estimatedDuration
                }
            } else {
                totalHours += dur || 0
                completedHours += clDoneHours
            }
        }
    })

    return {
        totalHours: totalHours,
        completedHours: completedHours,
        percentComplete: totalHours > 0 ? Math.round((completedHours / totalHours) * 100) : 0,
    }
}

export function getProjectHours(mission) {
    const allAi = getAllActionItems(mission)

    let totalHours = 0

    allAi.forEach((ai, i) => {
        if (!ai.checklist?.length || !ai.checklist?.find((cl) => cl.duration)) {
            totalHours += ai.estimatedDuration
        } else {
            let dur = 0
            ai.checklist.forEach((cl, i) => {
                dur += cl.duration || 0
            })

            if (dur < ai.estimatedDuration) {
                totalHours = ai.estimatedDuration || 0
            } else {
                totalHours = dur || 0
            }
        }
    })

    return totalHours
}

export function getRoleActuals(mission, roles, timesheets = [], from, to) {
    if (typeof from === "number") {
        from = toZonedTime(from)
    }
    if (typeof to === "number") {
        to = toZonedTime(to)
    }

    if (isBefore(to, from)) {
        console.error("Dates are an issue in getRoleActuals in missionUtils")
        return {
            totalForecastBillable: 0,
            totalAccruedCosts: 0,
            totalBillableAccrued: 0,
            totalBillableHours: 0,
        }
    }

    let totalForecastBillable = 0
    let totalBillableAccrued = 0
    let totalAccruedCosts = 0
    let totalBillableDays = 0
    let totalBillableHours = 0
    let timesheetBillableHours = 0

    roles
        .filter((pi) => pi.type === "person")
        .forEach((pi, i) => {
            const myAccrueEoM = endOfMonth(new Date())
            let dateToAccrueTo = isAfter(myAccrueEoM, to) && !isSameMonth(myAccrueEoM, to) ? to : myAccrueEoM
            if (isAfter(dateToAccrueTo, from)) {
                totalAccruedCosts += calculatePlanItemBudgetX(pi, mission, from, dateToAccrueTo)
            }
            totalForecastBillable += calculatePlanItemBudgetX(pi, mission, from, to, false, true)

            const myTimeSheets = (timesheets || []).filter((ts) => ts.status === "approved" && ts.planItemId === pi._id)

            const tsActuals = caluclateActualsFromTimesheets(mission, myTimeSheets, from, to)

            totalBillableAccrued += tsActuals.totalBillableAccrued || 0
            totalBillableDays += tsActuals.totalBillableDays || 0
            totalBillableHours += tsActuals.totalBillableHours || 0
        })

    return {
        totalForecastBillable: totalForecastBillable,
        totalAccruedCosts: totalAccruedCosts,
        totalBillableAccrued: totalBillableAccrued,
        totalBillableDays: totalBillableDays,
        totalBillableHours: totalBillableHours || 0,
    }
}

export function getForecastRevenue(mission, from, to, includeLost) {
    if (!mission.isProposal || (!includeLost && mission.proposalApproved === false)) {
        return 0
    }

    let fc = 0

    const isInStart = isWithinInterval(toZonedTime(mission.forecastStartDate), {
        start: from,
        end: to,
    })
    const isInEnd = isWithinInterval(toZonedTime(mission.forecastEndDate), {
        start: from,
        end: to,
    })

    if (!mission.proposalBillMonthly) {
        if (mission.proposalBillUpFront > 0 && isInStart) {
            fc = (mission.proposalBillUpFront / 100) * mission.forecastBudget || 0
        } else if (mission.proposalBillUpFront > 0 && isInEnd) {
            fc = ((100 - mission.proposalBillUpFront) / 100) * mission.forecastBudget || 0
        } else if ((!mission.proposalBillUpFront || mission.proposalBillUpFront === 0) && isInEnd) {
            fc = mission.forecastBudget || 0
        }
    }

    return fc
}

export function getHourlyBillRate(planItem) {
    if (!planItem) return null
    const br = planItem?.billRate || 0
    const bu = planItem?.billUnit || "Hourly"

    if (br === 0) {
        return 0
    }

    if (bu === "Hourly") {
        return planItem.billRate
    } else if (bu === "Daily") {
        return br / 8
    } else if (bu === "Weekly") {
        return br / 8 / 5
    } else if (bu === "Monthly") {
        return (12 * br) / 52 / 5 / 8
    }
}

export function caluclateActualsFromTimesheets(mission, timesheets, from, to, justForPeriod) {
    let totalBillableAccrued = 0,
        totalBillableDays = 0,
        totalBillableHours = 0,
        totalHours = 0,
        totalCostAccrued = 0

    timesheets.forEach((ts, i) => {
        if (ts.org) return //corporate non-billable

        const tsHours = getTimesheetHours(ts)

        if (!tsHours || ts.status !== "approved") {
            return {
                totalBillableAccrued: 0,
                totalBillableDays: 0,
                totalBillableHours: 0,
                totalCostAccrued: 0,
            }
        }

        if (
            justForPeriod &&
            !areIntervalsOverlapping(
                {
                    start: from,
                    end: to,
                },
                {
                    start: toZonedTime(ts.weekStart),
                    end: toZonedTime(ts.weekEnd),
                }
            )
        ) {
            return {
                totalBillableAccrued: 0,
                totalBillableDays: 0,
                totalBillableHours: 0,
                totalCostAccrued: 0,
            }
        }

        let thisTimeSheetsHours = tsHours
        let timesheetBillableHours = 0

        //if (ts.timeTrackingType === "daily") {

        const tsStart = toZonedTime(ts.weekStart)
        const tsEnd = toZonedTime(ts.weekEnd)

        const startIndex =
            isSameWeek(from, tsStart, { weekStartsOn: 1 }) && isAfter(from, tsStart)
                ? differenceInDays(from, tsStart)
                : getISODay(tsStart) - 1
        const endIndex =
            isSameWeek(to, tsEnd, { weekStartsOn: 1 }) && isBefore(to, tsEnd)
                ? differenceInDays(tsEnd, to)
                : getISODay(tsEnd) - 1

        ts.actionItems.forEach((taskItem, i) => {
            if (taskItem.nonBillable) return

            timesheetBillableHours += getTimeEntryHours(taskItem) || 0
        })

        totalBillableHours += timesheetBillableHours

        //Costs
        const todaysCuttOff = endOfDay(subDays(new Date(), 1))

        const cuttOffDay = isAfter(to, todaysCuttOff) ? todaysCuttOff : to
        const myRolePlanItem = mission.planItems.find((p) => p.person === getRefId(ts.person))

        //Check if in range
        let startDateOfItem =
            myRolePlanItem && isBefore(from, toZonedTime(myRolePlanItem.startDate))
                ? toZonedTime(myRolePlanItem.startDate)
                : from

        //Revenue *****

        let fullDays = thisTimeSheetsHours / 8

        const br = ts.snapshot?.planItem?.billRate || ts.billRate || 0
        const bu = ts.snapshot?.planItem?.billUnit || ts.billUnit || "Hourly"

        const cr = ts.snapshot?.planItem?.rate || ts.rate || 0
        const cu = ts.snapshot?.planItem?.rateTime || ts.rateTime || "Hourly"

        if (br >= 1) {
            if (bu === "Hourly") {
                totalBillableAccrued += timesheetBillableHours * br
            } else if (bu === "Daily") {
                let fDays = fullDays

                if (timesheetBillableHours > 0)
                    if (fullDays < 1) {
                        totalBillableDays += timesheetBillableHours > 4 ? 1 : 0.5
                        totalBillableAccrued += totalBillableDays * br
                    } else {
                        fDays = parseInt(fullDays)
                        const remainderHours =
                            timesheetBillableHours % 8 === 0 ? 0 : timesheetBillableHours % 8 > 4 ? 1 : 0.5
                        totalBillableAccrued += fDays * br + remainderHours * br

                        totalBillableDays += fDays + remainderHours
                    }
            } else if (bu === "Weekly") {
                const hourlyRate = br / (mission.hoursPerDay || 8 * 40)
                const hourlyCost = cr / (mission.hoursPerDay || 8 * 40)
                totalBillableAccrued = hourlyRate * timesheetBillableHours
            } else if (bu === "Monthly") {
                const dayRate = (12 * br) / 52 / 5
                const dayCost = (12 * cr) / 52 / 5
                const hourlyRate = dayRate / (mission.hoursPerDay || 8)
                const hourlyCost = dayCost / (mission.hoursPerDay || 8)

                totalBillableAccrued = hourlyRate * timesheetBillableHours
            }
        }
        if (cr >= 1) {
            if (cu === "Hourly") {
                totalCostAccrued += timesheetBillableHours * cr
            } else if (cu === "Daily") {
                let fDays = fullDays

                if (timesheetBillableHours > 0)
                    if (fullDays < 1) {
                        totalBillableDays += timesheetBillableHours > 4 ? 1 : 0.5

                        totalCostAccrued += totalBillableDays * cr
                    } else {
                        fDays = parseInt(fullDays)
                        const remainderHours =
                            timesheetBillableHours % 8 === 0 ? 0 : timesheetBillableHours % 8 > 4 ? 1 : 0.5

                        totalCostAccrued += fDays * cr + remainderHours * cr
                    }
            } else if (cu === "Weekly") {
                const hourlyRate = br / (mission.hoursPerDay || 8 * 40)
                const hourlyCost = cr / (mission.hoursPerDay || 8 * 40)

                totalCostAccrued = hourlyCost * timesheetBillableHours
            } else if (cu === "Monthly") {
                const dayRate = (12 * br) / 52 / 5
                const dayCost = (12 * cr) / 52 / 5
                const hourlyRate = dayRate / (mission.hoursPerDay || 8)
                const hourlyCost = dayCost / (mission.hoursPerDay || 8)

                totalCostAccrued = hourlyCost * timesheetBillableHours
            }
        }
    })

    return {
        totalBillableAccrued: totalBillableAccrued,
        totalBillableDays: totalBillableDays,
        totalBillableHours: totalBillableHours,
        totalCostAccrued: totalCostAccrued,
        totalHours: totalHours,
    }
}

export function calculatePersonBillable(pi, mission, timesheets, from, to) {
    if (mission.zeroBill || mission.notBillable || mission.billUsingMilestones) {
        return 0
    }
    if (!from || !to) {
        from = toZonedTime(pi.startDate)
        to = toZonedTime(pi.endDate)
    }

    if (isAfter(from, to)) {
        console.error("calculatePersonBillable from to error")
        return 0
    }

    if (mission.projectType === "classic-gantt" && pi.pinnedTo === "taskDates") {
        let billMe = 0
        const allRoleSubTasks = getAllActionItems(mission)
            .filter((ai) => {
                return ai.checklist?.find((c) => c.rolePlanItemId === pi._id)
            })
            .forEach((ai, i) => {
                ai.checklist.forEach((c, i) => {
                    if (c.rolePlanItemId === pi._id) {
                        billMe += (c.duration || 0) * c.billRate
                    }
                })
            })

        return billMe
    }

    if (
        (pi.personLeftRole && isAfter(toZonedTime(pi.startDate), startOfDay(new Date()))) ||
        !areIntervalsOverlapping(
            {
                start: toZonedTime(pi.startDate),
                end: toZonedTime(pi.endDate),
            },
            {
                start: from,
                end: to,
            }
        )
    ) {
        return 0
    }
    if (pi.hoursNeeded === 0 && pi.hoursNeeded !== null) return 0
    if (!pi.hoursNeeded) return calculatePlanItemBudgetX(pi, mission, from, to, null, true)

    let myAccumulatedRevenue = 0

    if (pi.billUnit === "Hourly") {
        return (myAccumulatedRevenue = pi.hoursNeeded * pi.billRate)
    } else if (pi.billUnit === "Daily") {
        const hourlyRate = pi.billRate / (mission.hoursPerDay || 8)
        myAccumulatedRevenue = pi.hoursNeeded * hourlyRate
    } else if (pi.billUnit === "Weekly") {
        const hourlyRate = pi.billRate / (mission.hoursPerDay || 8 * 40)
        myAccumulatedRevenue = hourlyRate * pi.hoursNeeded
    } else if (pi.billUnit === "Monthly") {
        const dayRate = (12 * pi.billRate) / 52 / 5
        const hourlyRate = dayRate / (mission.hoursPerDay || 8)
        myAccumulatedRevenue = hourlyRate * pi.hoursNeeded
    }

    return myAccumulatedRevenue * pi.allocation
}

export function getMissingTimesheetsAcrossMissions(timesheets, missions, from, to) {
    let missingTimesheets = []

    const lastDate = endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })

    if (isAfter(from, lastDate)) return []

    const endDate = isAfter(lastDate, to) ? to : lastDate

    const applicableWeeks = eachWeekOfInterval(
        {
            start: from,
            end: endDate,
        },
        { weekStartsOn: 1 }
    )

    applicableWeeks.forEach((week, i) => {
        missions
            .filter((m) => existsTimeTracking(m) && m.noTimesheets !== true)
            .forEach((mission, i) => {
                const peopleRoles = mission.planItems.filter((p) => {
                    return (
                        p.type === "person" &&
                        p.person &&
                        p.startDate &&
                        p.endDate &&
                        areIntervalsOverlapping(
                            {
                                start: toZonedTime(p.startDate),
                                end: toZonedTime(p.endDate),
                            },
                            {
                                start: week,
                                end: endOfWeek(week, { weekStartsOn: 1 }),
                            }
                        ) &&
                        areIntervalsOverlapping(
                            {
                                start: toZonedTime(mission.planStartDate),
                                end: toZonedTime(mission.planEndDate),
                            },
                            {
                                start: week,
                                end: endOfWeek(week, { weekStartsOn: 1 }),
                            }
                        )
                    )
                })

                peopleRoles.forEach((pr, i) => {
                    const myTimeSheets = timesheets.filter(
                        (ts) => ts.person === pr.person || ts.person === pr?.personLeftRole?.personId
                    )

                    const myMissing = getMissingTimesheets(myTimeSheets, week, endOfWeek(week, { weekStartsOn: 1 }))

                    missingTimesheets = [...missingTimesheets, ...myMissing]
                })
            })
    })

    return missingTimesheets
}

export function getMissingTimesheets(timesheets, from, to) {
    let missingTimesheets = []

    if (isAfter(from, startOfISOWeek(new Date()))) {
        return []
    }

    const lastDate = endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })

    if (isAfter(from, lastDate)) return []

    const endDate = isAfter(lastDate, to) ? to : lastDate

    if (isAfter(to, lastDate) || isSameWeek(new Date(), to, { weekStartsOn: 1 })) {
        if (differenceInWeeks(new Date(), from) > 3) {
            from = startOfISOWeek(subWeeks(new Date(), 3))
        }
    }

    const applicableWeeks = eachWeekOfInterval(
        {
            start: from,
            end: endDate,
        },
        { weekStartsOn: 1 }
    )

    applicableWeeks.forEach((week, i) => {
        const tsForThisPeriod = timesheets.find((ts) => {
            const tsStartDate = toZonedTime(ts.weekStart)
            const tsEndDate = toZonedTime(ts.weekEnd)

            const weekEndDate = endOfWeek(week, { weekStartsOn: 1 })

            const match = areIntervalsOverlapping(
                {
                    start: week,
                    end: weekEndDate,
                },
                {
                    start: tsStartDate,
                    end: tsEndDate,
                }
            )

            return match
        })

        if (
            !tsForThisPeriod ||
            (tsForThisPeriod && tsForThisPeriod.status !== "submitted" && tsForThisPeriod.status !== "approved")
        ) {
            missingTimesheets.push(week)
        }
    })

    return missingTimesheets
}

export function calculatePlanBudgetBasedOnTaskDuration(mission) {
    let budget = 0

    const actionItems = flatMap(getMissionActions(mission), "actionItems")

    if (actionItems.length === 0) return 0

    const roles = mission.planItems.filter((pi) => pi.type === "person" && pi.person && pi.rate > 0)

    roles.forEach((r) => {
        budget += myTimeAndMoney(r, actionItems, mission).cost
    })

    return budget
}

export function myTimeAndMoney(role, actionItems, mission) {
    let cost = 0

    let myActionItems = actionItems
        .filter((ai) => ai.people.findIndex((aip) => getRefId(aip) === getRefId(role.person)) !== -1)
        .sort((a, b) => {
            return a.startDate - a.endDate
        })

    if (myActionItems.length === 0) {
        return {
            cost: 0,
            time: 0,
        }
    }

    let durationImWorking = 0
    let myHourly = myHourlyRate(role, mission)

    myActionItems.forEach((mai) => (durationImWorking += mai.estimatedDuration))

    cost += durationImWorking * myHourly * (role.allocation / 100)

    return {
        cost: cost,
        time: durationImWorking,
    }
}

export function myHourlyRate(role, mission) {
    if (role.rateTime !== "Monthly") return 0

    const dpw = mission.daysPerWeek || 5
    const hpd = mission.hoursPerDay || 8

    return (role.rate * 12) / 52 / dpw / hpd
}

export function calculatePlanBudget(mission, ofType, customList) {
    //deprecated
    let itemsToPass = customList
    if (ofType && customList) {
        itemsToPass = intersection(
            mission.planItems.filter((p) => p.rate && p.type === ofType),
            customList
        )
    } else if (ofType) {
        itemsToPass = mission.planItems.filter((p) => p.rate && p.type === ofType)
    }

    //new

    return calculateMissionBudgetFromTo(mission, null, null, itemsToPass)
}

export function getWorkstream(mission, workstream, org) {
    let openRoles = [],
        assignedRoles = [],
        wsList = [],
        missionRoles = mission.planItems.filter((pi) => pi.type === "person")

    //Get all roles unfilled
    openRoles = missionRoles.filter((pi) => !pi.person) || []

    //Fake as a person
    openRoles = openRoles.map((or) => {
        return {
            _id: or._id,
            role: org ? getRole(or.role, org)?._id : or.role,
            ref: {
                _id: or._id,
                avatar: "/img/avatar-blur-red.jpg",
                firstName: "Unfilled",
                lastName: "Role",
            },
        }
    })

    let people = mission.people

    //Get all people aunassigned
    assignedRoles = missionRoles.filter((pi) => pi.person) || []

    assignedRoles.forEach((ar) => {
        const inx = people.findIndex((person) => getRefId(person) === ar.person)
        if (inx !== -1) {
            people[inx].role = ar.role
        }
    })

    //Clean up and format
    assignedRoles = formatMissionPeople(people)

    //Hydrate role and people ids into objects

    workstream.forEach((wsId) => {
        const persIndex = people.findIndex((obj) => getRefId(obj) === wsId)
        const planItemIndex = missionRoles.findIndex((obj) => obj._id === wsId)

        if (planItemIndex !== -1) {
            const zePerson = missionRoles[planItemIndex].person
            if (zePerson) {
                const wP = people.find((obj) => getRefId(obj) === zePerson)

                if (wP) wsList.push(wP)
            } else {
                const roleIndex = missionRoles.findIndex((obj) => obj._id === wsId)
                if (roleIndex !== -1) {
                    const or = missionRoles[roleIndex]
                    wsList.push({
                        firstName: or.role || "Untitled...",
                        lastName: "Unfilled...",
                        _id: or._id,
                        role: "Unfilled",
                        avatar: "/img/avatar-blur.jpg",
                        ref: {
                            _id: or._id,
                            firstName: or.role || "Untitled...",
                            lastName: "/ Role",
                        },
                    })
                }
            }
        } else {
            wsList.push(people[persIndex])
        }
    })

    return {
        workStream: wsList.filter((a) => a && a._id),
        searchList: assignedRoles.concat(openRoles).filter((a) => a && a._id),
    }
}

export function updateActionStartDates(phase, preChangePhase) {
    let newDatesArray = []

    if (!phase || !preChangePhase) {
        return []
    }

    if (phase.startDate === preChangePhase.startDate && phase.endDate === preChangePhase.endDate) {
        return []
    }

    phase.actions.forEach((a) => {
        let updateObj = {
            _id: a._id,
        }

        if (!a.startDate || !a.endDate) {
            updateObj.startDate = phase.startDate
            updateObj.endDate = getTimeUtc(endOfWeek(toZonedTime(phase.startDate), { weekStartsOn: 1 }))
            return
        }

        if (toZonedTime(phase.startDate) > new Date()) {
            let wIndex = differenceInCalendarWeeks(a.startDate, preChangePhase.startDate)

            wIndex = wIndex < 0 ? 0 : wIndex

            if (wIndex === 0) {
                const proposedStd = phase.startDate
                const std =
                    proposedStd < getTimeUtc(new Date())
                        ? getTimeUtc(startOfWeek(new Date(), { weekStartsOn: 1 }))
                        : proposedStd

                updateObj.startDate = std
                updateObj.endDate = getTimeUtc(endOfWeek(std, { weekStartsOn: 1 }))
                newDatesArray.push(updateObj)
            } else {
                const proposedStd = startOfWeek(addWeeks(phase.startDate, wIndex), { weekStartsOn: 1 })
                const std = proposedStd < new Date() ? startOfWeek(new Date(), { weekStartsOn: 1 }) : proposedStd

                let endWeekDay = getTimeUtc(endOfWeek(std, { weekStartsOn: 1 }))

                updateObj.startDate = getTimeUtc(std)
                updateObj.endDate = endWeekDay > phase.endDate ? phase.endDate : endWeekDay
                newDatesArray.push(updateObj)
            }
        } else if (phase.endDate <= a.endDate) {
            const pe = toZonedTime(phase.endDate)

            updateObj.startDate = getTimeUtc(startOfWeek(pe, { weekStartsOn: 1 }))
            updateObj.endDate = getTimeUtc(pe)

            newDatesArray.push(updateObj)
        } else if (phase.startDate >= a.startDate) {
            updateObj.startDate = phase.startDate //already utc
            updateObj.endDate = getTimeUtc(endOfWeek(phase.startDate, { weekStartsOn: 1 }))
            newDatesArray.push(updateObj)
        } else if (differenceInCalendarDays(a.endDate, a.startDate) < 7) {
            const proposedStd = getTimeUtc(startOfWeek(toZonedTime(a.startDate), { weekStartsOn: 1 }))
            const std =
                proposedStd < getTimeUtc(new Date())
                    ? getTimeUtc(startOfWeek(new Date(), { weekStartsOn: 1 }))
                    : proposedStd

            updateObj.startDate = std
            updateObj.endDate = getTimeUtc(endOfWeek(std, { weekStartsOn: 1 }))
            newDatesArray.push(updateObj)
        }
    })

    return newDatesArray
}

export function getActionStartDate(action, phase) {
    return action.startDate

    /*
    const myStartDate = action.week<=0? new Date(phase.startDate) : addWeeks(startOfWeek(phase.startDate,{weekStartsOn:1}),action.week<=0?0:action.week)

    return myStartDate*/
}

export function calculateFundsRemaining(mission) {
    if (mission.spentToDate && mission.budgetAvailable) {
        return mission.budgetAvailable - mission.spentToDate
    } else {
        return mission.budgetAvailable
    }
}

export function getWeekHistory(m, date) {
    let removedItems = []
    let completedItems = []
    let notCompleded = []
    let workedOn = [] //all

    const sdat = getTimeUtc(startOfWeek(date, { weekStartsOn: 1 }))
    const edat = getTimeUtc(endOfWeek(date, { weekStartsOn: 1 }))

    apiActionItem
        .getHistoryByMission(m._id, {
            createdAtFrom: sdat,
            createdAtTo: edat,
        })
        .then((res) => {
            removedItems = res.filter(
                (item) =>
                    item.change &&
                    item.change.updatedFields.startDate === null &&
                    item.change.updatedFields.startHour === null &&
                    item.change.updatedFields.y === null
            )
            removedItems = uniqBy(removedItems, "_id")

            completedItems = res.filter((item) => item.change && item.change.updatedFields.status === "done")
            completedItems = uniqBy(completedItems, "_id")

            return {
                removedItems: removedItems,
                completedItems: completedItems,
                notCompleded: notCompleded,
                workedOn: workedOn,
            }
        })
        .catch((err) => {})
}

//deprecated
export function calculateBudgetFromTo(mission, from, to, customList) {
    return calculateMissionBudgetFromTo(mission, from, to, customList)
}

export function deriveEndDate(ai) {
    if (ai.endDate) {
        return typeof ai.endDate === "number" ? endOfDay(toZonedTime(ai.endDate)) : ai.endDate
    }

    const hoursPerDay = 8

    let daysDur = ai.estimatedDuration / hoursPerDay

    let myDeEndDate = daysDur > 1 ? addDays(toZonedTime(ai.startDate), daysDur) : endOfDay(toZonedTime(ai.startDate))

    myDeEndDate = new Date(myDeEndDate)

    if (daysDur % 1 !== 0 && daysDur > 1) {
        myDeEndDate = addDays(myDeEndDate, 1)
    }

    if (myDeEndDate.getHours() === 0) {
        myDeEndDate = subDays(myDeEndDate, 1)
    }

    const finalEndDate = endOfDay(myDeEndDate)

    return finalEndDate
}

export function getMissionDates(mission) {
    if (!mission) return null
    const piDates = getMissionStartEndBasedOnPlanItems(mission.planItems)

    if (!piDates) {
        return {
            startDate: mission.planStartDate ? toZonedTime(mission.planStartDate) : null,
            endDate: mission.planEndDate ? toZonedTime(mission.planEndDate) : null,
        }
    }

    const start = isBefore(toZonedTime(mission.planStartDate), piDates.startDate)
        ? toZonedTime(mission.planStartDate)
        : piDates.startDate
    const end = isBefore(piDates.endDate, toZonedTime(mission.planEndDate))
        ? toZonedTime(mission.planEndDate)
        : piDates.endDate

    return {
        startDate: start,
        endDate: end,
    }
}

export function getMissionStartEndBasedOnPlanItems(planItems) {
    if (planItems && planItems.length === 0) {
        return null
    }

    let items = planItems.filter((pi) => pi && pi.startDate && pi.endDate)

    return {
        startDate: toZonedTime(items.sort((a, b) => a.startDate - b.startDate)[0].startDate),
        endDate: toZonedTime(items.sort((a, b) => b.endDate - a.endDate)[0].endDate),
    }
}

export function calculateMissionBudgetFromTo(mission, theFromDate, theToDate, customList, ofType, isForecast) {
    if (!mission) return 0

    const zePlanStartDate = isForecast ? mission.forecastStartDate : mission.planStartDate
    const zePlanEndDate = isForecast ? mission.forecastEndDate : mission.planEndDate

    let from, to

    if (!theFromDate || !theToDate) {
        if (zePlanStartDate && zePlanEndDate) {
            from = toZonedTime(zePlanStartDate)
            to = toZonedTime(zePlanEndDate)
        } else if (mission.planItems && mission.planItems.length) {
            let derivedStartEnd = getMissionStartEndBasedOnPlanItems(mission.planItems)

            from = derivedStartEnd.startDate
            to = derivedStartEnd.endDate
        } else {
            return 0
        }
    } else {
        from = typeof theFromDate === "number" ? toZonedTime(theFromDate) : theFromDate
        to = typeof theToDate === "number" ? toZonedTime(theToDate) : theToDate
    }

    if (!from || !to || isBefore(to, from)) return 0

    let whpw = mission.workHoursPerWeek || 40

    let budget = 0

    let myItems = customList ? customList : mission.planItems

    myItems.forEach((planItem) => {
        if (
            !planItem.rate &&
            (!planItem.startDate ||
                !planItem.endDate ||
                planItem.type === "phase" ||
                planItem.isBillable ||
                planItem.billRate ||
                (planItem.personLeftRole && isAfter(toZonedTime(planItem.startDate), startOfDay(new Date()))))
        ) {
            return 0
        }

        let planItemStartDate = toZonedTime(planItem.startDate)
        let planItemEndDate = toZonedTime(planItem.endDate)

        if (isBefore(planItemEndDate, planItemStartDate)) {
            apiPlanItems.deletePlanItem(planItem._id)
            return 0
        }

        let isInInterval = false

        try {
            isInInterval = areIntervalsOverlapping(
                {
                    start: planItemStartDate,
                    end: planItemEndDate,
                },
                {
                    start: from,
                    end: to,
                }
            )
        } catch (e) {}
        const isIn1 = from && to && planItemStartDate && planItemEndDate && isInInterval

        //Check Monthly

        if (!isIn1) {
            return 0
        }

        budget += calculatePlanItemBudgetX(planItem, mission, from, to, isForecast)
    })

    const allAi = getAllActionItems(mission)

    allAi.forEach((ai) => {
        try {
            const dayToCheck = deriveEndDate(ai)

            if (ai.rate || ai.cost) {
                if (
                    isSameDay(toZonedTime(mission.planStartDate), from) &&
                    isSameDay(toZonedTime(mission.planEndDate), to)
                ) {
                    budget += ai.rate || ai.cost || 0
                } else {
                    if (
                        ai.startDate &&
                        isWithinInterval(dayToCheck, {
                            start: from,
                            end: to,
                        })
                    )
                        budget += ai.rate || ai.cost || 0
                }
            }
        } catch (err) {}
    })

    return budget
}

export function calculatePlanItemBudgetX(
    planItem,
    mission,
    startingDate,
    endingDate,
    skipForecastCheck,
    calculateBillable
) {
    const rateToUse = calculateBillable ? planItem.billRate : planItem.rate
    const timeToUse = calculateBillable && planItem.type === "person" ? planItem.billUnit : planItem.rateTime
    const dayCalculationToUse =
        planItem.type === "cost" || planItem.includeWeekends ? differenceInDays : differenceInBusinessDays

    const daysAmountToUse = planItem.type === "cost" || planItem.includeWeekends ? 7 : 5
    if (planItem.isForecast && !skipForecastCheck) return 0

    if (!startingDate && !endingDate) {
        startingDate = toZonedTime(planItem.startDate)
        endingDate = toZonedTime(planItem.endDate)
    }

    let from = typeof startingDate === "number" ? startOfDay(toZonedTime(startingDate)) : startOfDay(startingDate)
    let to = typeof endingDate === "number" ? endOfDay(toZonedTime(endingDate)) : endOfDay(endingDate)

    if (!from || !to || !planItem.startDate || !planItem.endDate || isAfter(from, to)) return 0

    if (timeToUse === "One time") {
        if (!isWithinInterval(toZonedTime(planItem.endDate), { start: from, end: to })) {
            return 0
        }

        return rateToUse * (ensureNumber(planItem.quantity) || 1)
    }

    let overLap = false

    const t1 = toZonedTime(planItem.startDate)
    const t2 = toZonedTime(planItem.endDate)

    if (isSameDay(t1, t2)) {
        overLap = isWithinInterval(from, {
            start: t1,
            end: t2,
        })
    } else {
        try {
            overLap = areIntervalsOverlapping(
                {
                    start: from,
                    end: to,
                },
                {
                    start: t1,
                    end: t2,
                }
            )
        } catch (err) {
            console.error(err)
        }
    }

    if (!overLap) {
        return 0
    }

    if (!rateToUse || isBefore(to, from)) {
        return 0
    }

    if (isBefore(from, toZonedTime(planItem.startDate))) {
        from = startOfDay(toZonedTime(planItem.startDate))
    }
    if (isAfter(to, toZonedTime(planItem.endDate))) {
        to = endOfDay(toZonedTime(planItem.endDate))
    }

    const adjustedTo = to.getHours() === 23 ? addMilliseconds(to, 1) : to
    let budget = 0
    const allocation = planItem.allocation ? planItem.allocation / 100 : 1

    const qty = ensureNumber(planItem.quantity) || 1

    if (timeToUse === "Monthly" || timeToUse === "Recurring") {
        //const workHours = mission && mission.workHoursPerWeek ? mission.workHoursPerWeek : 40
        const dayRate = (12 * rateToUse) / 52 / daysAmountToUse

        if (planItem.hoursNeeded) {
            const hourRate = dayRate / 8
            budget += hourRate * planItem.hoursNeeded
            //return
        } else {
            let fullMonths = eachMonthOfInterval({
                start: from,
                end: to,
            }).length

            if (!isLastDayOfMonth(to)) {
                fullMonths--
            }
            if (!isFirstDayOfMonth(from)) {
                fullMonths--
            }

            if (fullMonths === 0) {
                budget = dayCalculationToUse(adjustedTo, from) * dayRate
            } else {
                budget = fullMonths * rateToUse * qty

                if (!isFirstDayOfMonth(from)) {
                    //get partial month days at the start
                    const a1 = dayCalculationToUse(addMilliseconds(endOfMonth(from), 1), from)

                    budget += a1 * dayRate * qty
                }
                if (!isLastDayOfMonth(to)) {
                    //get partial month days at the end
                    const a2 = dayCalculationToUse(adjustedTo, startOfMonth(to))

                    budget += a2 * dayRate * qty
                }
            }
        }
    } else if (timeToUse === "Hourly") {
        const hours = planItem.hoursNeeded
            ? planItem.hoursNeeded
            : dayCalculationToUse(addMilliseconds(to, 1), from) * (mission.hoursPerDay || 8)

        return hours * rateToUse * qty * (planItem.hoursNeeded ? 1 : allocation)
    } else if (timeToUse === "Weekly") {
        const dayRate = rateToUse / daysAmountToUse

        if (planItem.hoursNeeded) {
            const hourRate = dayRate / 8
            budget += hourRate * planItem.hoursNeeded
            //return
        } else {
            let extraDays = 0

            const fullWeeks = differenceInWeeks(addMilliseconds(to, 1000), from, { weekStartsOn: 1 })
            const days = dayCalculationToUse(addMilliseconds(to, 1000), from)

            if (days > fullWeeks * daysAmountToUse) {
                extraDays = days - fullWeeks * daysAmountToUse
            }

            if (isMonday(from) && isFriday(to) && extraDays) {
                budget += (fullWeeks + 1) * rateToUse * qty
            } else {
                budget += fullWeeks * rateToUse + extraDays * dayRate * qty
            }
        }
    } else if (timeToUse === "Yearly") {
        const dayRate = rateToUse / 52 / daysAmountToUse
        if (planItem.hoursNeeded) {
            const hourRate = dayRate / 8
            budget += hourRate * planItem.hoursNeeded
            //return
        } else {
            budget = (planItem.rate / 12) * differenceInMonths(adjustedTo, from)
        }
    } else if (timeToUse === "Daily") {
        if (planItem.hoursNeeded) {
            const hourRate = planItem.rate / 8
            budget = hourRate * planItem.hoursNeeded
            //return
        } else {
            let days = 0

            days = dayCalculationToUse(adjustedTo, from)

            budget = days * rateToUse * qty
        }
    } else if (timeToUse === "One time") {
        budget = rateToUse * qty
    }

    if (planItem.type === "person") {
        budget = budget * (planItem.hoursNeeded ? 1 : allocation)
    }

    return budget
}

export function calculatePlanItemBudget(cost, timeframe, from, to, allocation, workHours) {
    return 0

    from = typeof from === "number" ? startOfDay(toZonedTime(from)) : startOfDay(from)
    to = typeof to === "number" ? endOfDay(toZonedTime(to)) : endOfDay(to)

    if (!cost || isBefore(to, from)) {
        return 0
    }

    let budget = 0

    allocation = allocation ? allocation / 100 : 1

    const adjustedTo = to.getHours() === 23 ? addMilliseconds(to, 1) : to

    if (timeframe === "Monthly") {
        workHours = workHours ? workHours : 40
        const dayRate = (12 * cost) / 52 / 5

        let fullMonths = differenceInMonths(adjustedTo, from)

        let endPointDate = addMonths(from, fullMonths)

        let remiander = differenceInBusinessDays(adjustedTo, endPointDate)

        budget += fullMonths * cost
        budget += remiander * dayRate
    } else if (timeframe === "Yearly") {
        budget = (cost / 12) * differenceInMonths(adjustedTo, from)
    } else if (timeframe === "Daily") {
        const days = differenceInBusinessDays(adjustedTo, from)
        budget = days * cost
    } else {
        budget = cost
    }

    return budget * allocation
}

export function formatMoney(value) {
    return numeral(value || 0)
        .format(value > 1000000 ? "0.0a" : value >= 10000 ? "0.0a" : "0,0")
        .toUpperCase()
}

export function formatMissionPeople(people, mission) {
    let newPeople = []

    if (people && !Array.isArray(people)) return []

    if (!people || people.length === 0) return []

    people.forEach((fp) => {
        if (typeof fp === "string") {
            if (!mission) {
                return
            }

            fp = mission.people.find((p) => getRefId(p) === fp)
        }

        if (!fp) {
            return
        }

        let person = JSON.parse(JSON.stringify(fp))

        if (!person.ref) {
            person.ref = person
        }
        if (person.customRole?.length) {
            person.role = person.customRole.map((cr) => getCustomRoleLabelByCustomRole(cr)).join(", ")
        } else if (person.permission === 0) {
            person.role = "Team member"
        } else if (person.permission === 2) {
            person.role = "Project owner - Admin"
        } else if (person.permission === 1 && person.isProjectManager) {
            person.role = "Project manager"
        } else if (person.permission === 1) {
            person.role = "Team lead"
        } else {
            person.role = "Observer"
        }

        if (person && person.ref && person.invitePending) {
            let pObject = person

            if (person.ref.firstName && person.ref.avatar && person.ref.lastName) {
                pObject = {
                    ...pObject,
                    className: "dna-person-pending " + (person.className || ""),
                    msg: (
                        <>
                            {person.email && (
                                <>
                                    {person.email} <br />
                                </>
                            )}

                            <span className="dna-hl pink"> Hasn't accepted the project invite yet</span>
                            <br />
                            {person.msg}
                        </>
                    ),
                    invitePending: person.invitePending,
                    ref: person.ref,
                }
            } else {
                pObject = {
                    ...pObject,
                    className: "dna-person-pending " + (person.className || ""),
                    msg: (
                        <>
                            <span className="dna-hl pink"> Hasn't accepted the project invite yet</span>
                            <br />
                            {person.msg}
                        </>
                    ),
                    role: "Team member",
                    ref: {
                        ...person.ref,
                        avatar: "/img/avatar-blur.jpg",
                        firstName: "New",
                        lastName: "User",
                        email: person.email,
                        invitePending: person.invitePending,
                    },
                }
            }

            newPeople.push(pObject)
        } else {
            newPeople.push(person)
        }
    })

    //

    return newPeople
}

export function getPhaseActionDetails(phase, mission) {
    let actions = {
        done: 0,
        blocked: 0,
        total: phase.actions.length,
        actionItems: 0,
    }

    phase.actions.forEach((a) => {
        let ga = getActionStatus(a, mission)
        actions.done += ga.isDone ? 1 : 0
        actions.blocked += ga.blocked
        actions.actionItems += ga.actionItems
    })

    return actions
}

export function getActionItemStatus(ai, mission) {
    let dueDate = getAiDueDate(ai)
    let stuckInThePast = ai.week ? isBefore(toZonedTime(ai.week), startOfISOWeek(new Date())) : false

    if (mission?.type === "kanban") {
        stuckInThePast = false
    }

    const isOverDue = stuckInThePast
        ? true
        : !dueDate
        ? false
        : mission?.isTemplate
        ? false
        : ai.status !== "done" && dueDate && isBefore(dueDate, new Date())

    return {
        dueDate: dueDate,
        isOverDue: isOverDue,
    }
}

export function getActionStatus(action, mission) {
    if (!action || typeof action === "string") return

    if (!action.actionItems) action.actionItems = []

    const done = action.actionItems.filter((a) => a.status === "done" && !a.aiAction).length
    const l = action.actionItems.length

    let cost = 0
    let overdue = []

    action.actionItems.forEach((ai) => {
        if (!ai || typeof ai === "string") return

        const st = getActionItemStatus(ai, mission)
        if (ai.status !== "done" && st.isOverDue) {
            overdue.push(ai)
        }
        if (ai.cost) {
            cost += ai.cost
        }
    })

    return {
        cost: cost,
        isDone: action.status === "done" || l === done,
        actionItems: l,
        overdueItems: overdue,
        completActionItems: done || 0,
        actionItemsLeft: l - done,
        percentComplete: Math.round((done / l) * 100),
        blockedItems: action.actionItems.filter((a) => a.status === "blocked"),
    }
}

export function getPhaseStats(phase, mission) {
    if (typeof phase === "string") {
        phase = mission.planItems.find((p) => p._id === phase)
    }

    if (!phase) return

    let numberComplete = 0
    let overdueItems = 0
    let blocked = false
    let actionItems = 0
    let actionItemsLeft = 0
    let actionItemsComplete = 0
    let myBudget = 0
    let myMinBudget = null

    if (!phase.actions) return
    phase.actions.forEach((a) => {
        if (typeof a === "string") {
            a = mission.actions.find((p) => p._id === a)
        }

        if (!a) return

        const deets = getActionStatus(a, mission)

        overdueItems += deets.overdueItems.length

        actionItems += deets.actionItems

        actionItemsLeft += deets.actionItemsLeft

        actionItemsComplete += deets.completActionItems

        if (deets.isDone) numberComplete += 1

        if (deets.isBlocked) blocked = true
    })

    if (phase.workStream && phase.workStream.length && mission) {
        phase.workStream.forEach((id) => {
            const foundRole = mission.planItems
                .filter((pi) => pi.type === "person")
                .find((pi) => pi._id === id || pi.person === id)

            if (foundRole) {
                const howManyPhasesIsRoleOn = mission.planItems
                    .filter((obj) => obj.type === "phase" && obj.workStream && obj.workStream.length)
                    .filter((obj) => {
                        return obj.workStream.findIndex((w) => w === foundRole.person) !== -1
                    })

                let whatStartDate = foundRole.startDate < phase.startDate ? phase.startDate : foundRole.startDate
                let whatEndDate = foundRole.endDate > phase.endDate ? phase.endDate : foundRole.endDate
                let myRolesBudget = 0

                myRolesBudget = calculatePlanItemBudgetX(
                    foundRole,
                    mission,
                    toZonedTime(foundRole.startDate),
                    toZonedTime(foundRole.endDate)
                )

                myBudget += myRolesBudget

                if (howManyPhasesIsRoleOn.length) myMinBudget += myRolesBudget / howManyPhasesIsRoleOn.length
            }
        })
    }

    if (mission)
        mission.planItems
            .filter((pi) => pi.type === "cost")
            .forEach((c) => {
                if (c.tags) {
                    if (c.tags.findIndex((t) => t === "Phase: " + phase.title) !== -1) {
                        myBudget += calculatePlanItemBudgetX(
                            c,
                            mission,
                            toZonedTime(c.startDate),
                            toZonedTime(c.endDate)
                        )
                    }
                }
            })

    return {
        budget: myBudget,
        minBudget: myMinBudget === myBudget ? null : myMinBudget,
        totalOverdue: overdueItems,
        isBlocked: blocked,
        totalActions: phase.actions.length,
        totalActionItems: actionItems, // number
        totalsActionItemsLeft: actionItemsLeft, //number
        completeActions: numberComplete,
        completeActionItems: actionItemsComplete,
        actionsLeft: phase.actions.length - numberComplete,
        percentComplete: +numeral((actionItemsComplete / actionItems) * 100).format(0, 0),
    }
}

export function getPhaseCompletePercent(phase, missionStop) {
    let completeItemsToDate = 0
    let totalItems = 0
    let aComplete = 0
    let aTotal = 0

    let cuttOff = endOfWeek(new Date(), { weekStartsOn: 1 })
    const today = new Date()

    if (missionStop && missionStop < cuttOff.getTime()) {
        cuttOff = new Date(missionStop)
    }

    const totalDays = differenceInCalendarDays(phase.endDate, phase.startDate)
    const daysPast = differenceInCalendarDays(new Date(), phase.startDate)
    //  const daysAhead = differenceInCalendarDays(phase.endDate, phase.startDate)

    const actionsToDate = phase.actions.filter((a) => {
        return toZonedTime(a.endDate).getTime() <= cuttOff.getTime()
    })

    actionsToDate.forEach((a) => {
        completeItemsToDate += a.actionItems.filter((item) => item.status === "done").length
        totalItems += a.actionItems.length
    })

    phase.actions.forEach((a) => {
        aComplete += a.actionItems.filter((item) => item.status === "done").length
        aTotal += a.actionItems.length
    })

    const percentTimePast = today.getTime() > toZonedTime(phase.startDate).getTime() ? (daysPast / totalDays) * 100 : 0

    const x = totalItems === 0 ? 0 : completeItemsToDate / totalItems

    const black = x
    const red = 1 - black

    return {
        overallComplete: aComplete / aTotal,
        black: isNaN(black) ? percentTimePast : black * percentTimePast,
        red: totalItems === 0 ? 0 : red * percentTimePast,
        ahead: null,
    }
}

export function getMissionPercentComplete(mission) {
    if (mission.projectType === "power" || mission.projectType === "kanban") {
        return Math.round(calculatePercentComplete(mission))
    } else {
        const d = getProjectHoursFromTo(mission)

        return d.percentComplete
    }
}

export function calculatePercentComplete(mission) {
    let aiComplete = 0
    let aiTotal = 0

    if (!mission || !mission.planItems) return

    const phases = mission.planItems.filter((p) => p.type === "phase")
    phases.forEach((p) => {
        const stats = getPhaseStats(p)

        aiComplete += stats.completeActionItems || 0
        aiTotal += stats.totalActionItems || 0
    })

    let perc = aiTotal === 0 ? 0 : (aiComplete / aiTotal) * 100

    return isNaN(perc) ? 0 : perc
}

export function phaseCompletePercent(phase) {
    let itemsTotal = 0
    let itemComplete = 0
    phase.actions.forEach((action, i) => {
        if (action.status === "done") {
            itemsTotal++
            itemComplete++
        } else {
            action.actionItems?.forEach((ai, i) => {
                if (ai.status === "done") {
                    itemsTotal += 1
                    itemComplete += 1
                } else if (ai.checklist?.length) {
                    ai.checklist.forEach((cl, i) => {
                        itemsTotal++
                        if (cl.done) {
                            itemComplete++
                        }
                    })
                } else {
                    itemsTotal++
                }
            })
        }
    })

    return itemsTotal ? Math.round(ensureNumber((itemComplete / itemsTotal) * 100)) : 0
}

export function actionPercentComplete(action, mission) {
    let hours = 0
    let hoursComplete = 0
    let count = 0

    if (mission?.projectType === "power") {
        let itemsTotal = 0
        let itemComplete = 0
        if (action.status === "done") {
            itemsTotal++
            itemComplete++
        } else {
            action.actionItems?.forEach((ai, i) => {
                if (ai.status === "done") {
                    itemsTotal += 1
                    itemComplete += 1
                } else if (ai.checklist?.length) {
                    ai.checklist.forEach((cl, i) => {
                        itemsTotal++
                        if (cl.done) {
                            itemComplete++
                        }
                    })
                } else {
                    itemsTotal++
                }
            })
        }
        return itemsTotal ? Math.round(ensureNumber((itemComplete / itemsTotal) * 100)) : 0
    } else {
        action.actionItems.forEach((ai, i) => {
            if (ai.checklist?.length && ai.status !== "done") {
                ai.checklist.forEach((cl, i) => {
                    hours += cl.duration || 0
                    if (cl.done) {
                        hoursComplete += cl.duration || 0
                    }
                })
            } else {
                hours += ai.estimatedDuration || 0
                if (ai.status === "done") {
                    hoursComplete += ai.estimatedDuration || 0
                }
            }
        })
        const final = hours === 0 ? 0 : +(hoursComplete / hours).toFixed(1) * 100

        return ensureNumber(final)
    }
}

export function getActivePhases(mission, date) {
    if (!mission || !mission.planItems) return

    let relevantPhases = []

    const phases = mission.planItems.filter((p) => p.type === "phase")

    const dateStartOfWeek = startOfWeek(date, { weekStartsOn: 1 })
    const dateEndOfWeek = endOfWeek(date, { weekStartsOn: 1 })

    phases.forEach((phase) => {
        const zonedStartDate = toZonedTime(phase.startDate)
        let zonedEndDate = toZonedTime(phase.endDate)

        const abc = isWithinInterval(zonedStartDate, { start: dateStartOfWeek, end: dateEndOfWeek })
        const xyz = isWithinInterval(zonedEndDate, { start: dateStartOfWeek, end: dateEndOfWeek })
        const okb =
            zonedStartDate.getTime() <= dateStartOfWeek.getTime() && zonedEndDate.getTime() >= dateEndOfWeek.getTime()

        if (abc || xyz || okb) {
            relevantPhases.push(phase)
        }
    })

    relevantPhases.sort((a, b) => {
        return a.startDate - b.startDate
    })

    return relevantPhases
}

export function getPhaseInsights(phase) {}

export function getMissionPeople(mission, people) {
    const p = people.map((obj) => {
        if (typeof obj === "string") {
            return mission.people.find((p) => getRefId(p) === obj)
        } else {
            return p
        }
    })

    return p
}

export function getMissionPerson(mission, personId) {
    return (get(mission, "people") || []).find((item) => getObjectId(item.ref) === personId)
}

export function existsMissionPerson(mission, personId) {
    return mission?.people.some((item) => getObjectId(item.ref) === personId)
}

export function getBestPerformers(mission, metrics, amountOf = 1) {
    let items = []

    if (metrics) {
        items = Object.keys(metrics).map((key, ind) => {
            const obj = metrics[key]
            return {
                _id: key,
                ...obj,
            }
        })
    }

    const list = orderBy(
        items.filter((p) => p.performance),
        "performance",
        ["desc"]
    ).slice(0, amountOf)

    return list.map((p) => {
        return mission.people.find((mp) => getRefId(mp) === p._id)
    })
}

export function getMissionTopPerformers(missionPeople, missionInsights) {
    const insightsLatest = Array.isArray(missionInsights) ? missionInsights[0] : {}
    return (get(insightsLatest, "people") || [])
        .filter((p) => isFinite(p.performance))
        .sort((a, b) => b.performance - a.performance)
        .map((item) => (missionPeople || []).find((p) => getObjectId(p.ref) === item.personId))
}

export function getMissionPermissionLabel(permission) {
    return MISSION_PERMISSION_LABEL_MAP.get(permission)
}

/**
 * Returns the allowed permissions that a user can receive.
 *
 * @param personId {String|Object} The user id of the person to check against
 * @param missionPeople {Object[]} Mission people
 * @param [missionOrgPeople] {Object[]} Org people if the mission belongs to an org
 * @return {String[]|[]}
 */
export function getMissionPersonAllowedPermissions(personId, missionPeople, missionOrgPeople) {
    const personMission = missionPeople
        ? missionPeople.find((item) => getObjectId(item.ref) === getObjectId(personId))
        : null

    const personOrg = missionOrgPeople
        ? missionOrgPeople.find((item) => getObjectId(item.ref) === getObjectId(personId))
        : null
    const missionActiveAdmins = missionPeople
        ? missionPeople.filter((item) => item.permission === MISSION_PERMISSION.ADMIN && !item.invitePending)
        : []
    const missionOrgPermissions = [MISSION_PERMISSION.OBSERVER_ORG, MISSION_PERMISSION.OBSERVER_ORG_LIMITED]

    if (!personMission) {
        return []
    }

    if (
        !personMission.invitePending &&
        personMission.permission === MISSION_PERMISSION.ADMIN &&
        missionActiveAdmins.length === 1
    ) {
        // Cannot change the permission of the only active admin
        return []
    }
    const p = Object.values(MISSION_PERMISSION)
        .filter((item) => item !== personMission.permission)
        .filter((item) => {
            return !personOrg
                ? !missionOrgPermissions.includes(item)
                : !missionOrgPermissions.includes(item) ||
                      getMissionAllowedOrgPermissions(personOrg.permission).includes(item)
        })

    return p
}

export function getMissionAllowedOrgPermissions(orgPermission) {
    switch (orgPermission) {
        case ORG_PERMISSION.ADMIN:
        case ORG_PERMISSION.MANAGER:
            return [MISSION_PERMISSION.OBSERVER_ORG]
        default:
            return []
    }
}

/**
 * Returns a validation object, on whether a user can remove a mission person.
 *
 * @param userId {String|Object} The user id of the currently logged-in user
 * @param personId {String|Object} The user id of the person to remove
 * @param missionPeople {Object[]} Mission people
 * @return {{
 *     canRemove: Boolean,
 *     [message]: String,
 *     [code]: ('org_observer'|'only_admin'|'no_permission')
 * }}
 */

export function removePersonFromMission({ person, app, mission }) {
    const personInMission = mission.people.find((p) => getRefId(p) === getRefId(person))
    const meInMission = mission.people.find((p) => getRefId(p) === app.state.person._id)
    const orgData = getObjectId(mission.org) ? app.state.orgs.find((o) => o._id === getObjectId(mission.org)) : null
    const meInOrg = orgData?.people?.find((p) => getRefId(p) === app.state.person._id)
    const isProjectManager = meInMission?.isProjectManager || meInMission?.permission === 2
    const isOrgAdmin = meInOrg?.permission === 2
    const admins = mission.people.filter((p) => p.permission === 2).length
    const isAdmin = personInMission.permission === 2

    if (isAdmin && admins === 1) {
        toast.warning(
            "Sorry but you need at least one admin on the project. Try adding another admin first or make another person an admin.",
            { autoClose: 6000 }
        )
        return
    }

    if (isProjectManager || isOrgAdmin || meInMission.permission === 2) {
        app.confirm({
            severe: true,
            comp: <p>Remove this person from the project?</p>,
            yesColor: "red",
            onYes: () => {
                app.missionDeletePerson({
                    mission: mission,
                    person: person,
                })
            },
        })
    }
}
export function canUserRemoveMissionPerson(userId, personId, missionPeople) {
    try {
        const missionUser = missionPeople.find((item) => getObjectId(item.ref) === getObjectId(userId))
        const missionPerson = missionPeople.find((item) => getObjectId(item.ref) === getObjectId(personId))
        const missionActiveAdmins = missionPeople.filter((item) => item.permission === MISSION_PERMISSION.ADMIN)
        const isSamePerson = getObjectId(missionUser.ref) === getObjectId(missionPerson.ref)
        const hasPermissionToRemovePerson =
            isSamePerson || getPermissionsPersonCanRemove(missionUser).includes(missionPerson.permission)

        if (isMissionObserverOrg(missionPerson.permission)) {
            // Org observers cannot be removed from a mission.
            return {
                canRemove: false,
                message: `✋This is an Organization Manager and cannot be removed or interact with the mission.`,
                code: "org_observer",
            }
        }

        if (
            hasPermissionToRemovePerson &&
            get(missionPerson, "permission") === MISSION_PERMISSION.ADMIN &&
            missionActiveAdmins.length === 1 &&
            !missionPerson.invitePending
        ) {
            // A mission needs at least one active admin
            return {
                canRemove: false,
                message: "",
                code: "only_admin",
            }
        }

        if (hasPermissionToRemovePerson) {
            return {
                canRemove: true,
            }
        }

        return {
            canRemove: false,
            message: `No permission to delete`,
            code: "no_permission",
        }
    } catch (err) {
        // Something went wrong! Leave to the API to handle...
        return {
            canRemove: true,
        }
    }
}

/**
 * Determine if the logged-in user can change other member's permissions and which ones.
 *
 * @param userId {string} The currently logged-in user _id
 * @param toPermission {string} Any of the values of the MISSION_PERMISSION constant
 * @param mission {object} The full mission object
 * @param [org] {object} The full org object if it exists
 * @returns {boolean}
 */
export function canUserChangeMissionPersonToPermission(userId, toPermission, mission, org) {
    const userOrgPerson = org?.people?.find((person) => getObjectId(person.ref) === userId)
    const userMissionPerson = mission?.people?.find((person) => getObjectId(person.ref) === userId)

    if ([ORG_PERMISSION.ADMIN].includes(userOrgPerson?.permission)) {
        // God mode
        return true
    }

    if (
        [ORG_PERMISSION.MANAGER, ORG_PERMISSION.MANAGER_LIMITED].includes(userOrgPerson?.permission) &&
        userOrgPerson?.canApproveRoles === true
    ) {
        // Is project manager
        return true
    }

    if (
        [
            MISSION_PERMISSION.OBSERVER_ORG_LIMITED,
            MISSION_PERMISSION.OBSERVER_ORG,
            MISSION_PERMISSION.OBSERVER,
        ].includes(userMissionPerson?.permission)
    ) {
        // Observers cannot invite or change permission
        return false
    }

    if ((mission.whoCanInvite || []).length) {
        return (mission.whoCanInvite || []).includes(userMissionPerson?.permission)
    }

    const canInviteMemberPermissions = [MISSION_PERMISSION.OBSERVER, MISSION_PERMISSION.MEMBER]

    const canInviteManagerPermissions = [...canInviteMemberPermissions, MISSION_PERMISSION.MANAGER]

    const canInviteAdminPermissions = [...canInviteManagerPermissions, MISSION_PERMISSION.ADMIN]

    if ([MISSION_PERMISSION.MEMBER].includes(userMissionPerson?.permission)) {
        return canInviteMemberPermissions.includes(toPermission)
    }

    if ([MISSION_PERMISSION.MANAGER].includes(userMissionPerson?.permission)) {
        return canInviteManagerPermissions.includes(toPermission)
    }

    if ([MISSION_PERMISSION.ADMIN].includes(userMissionPerson?.permission)) {
        return canInviteAdminPermissions.includes(toPermission)
    }

    return false
}

/**
 * Returns a validation object, on whether a user can remove a mission person.
 *
 * @param mission {object}
 * @param mission.whoCanInvite {number[]}
 * @param mission.missionStop {number}
 * @param missionUser {object}
 * @param missionUser.permission {number}
 * @param missionUser.isProjectManager {boolean}
 *
 * @param [org] {object}
 * @param [org.rolesNeedApproval] {boolean}
 * @param [orgUser] {object}
 * @param [orgUser.permission] {number}
 * @param [orgUser.canApproveRoles] {boolean}
 * @param [orgUser.canApproveLeaves] {boolean}
 *
 * @return {boolean} If true, user can invite to the mission
 */
export function canUserInviteToMission(mission, missionUser, org = {}, orgUser = {}) {
    const status = getMissionStatus(mission)
    try {
        if (status !== "active") {
            return false
        }
        if (isMissionComplete(mission)) {
            return false
        }
        if (mission.missionStop && isAfter(new Date(), toZonedTime(mission.missionStop))) {
            // Mission is over
            return false
        }

        if ([ORG_PERMISSION.ADMIN].includes(orgUser?.permission)) {
            // God mode
            return true
        }

        if (
            [ORG_PERMISSION.MANAGER, ORG_PERMISSION.MANAGER_LIMITED].includes(orgUser?.permission) &&
            orgUser?.canApproveRoles === true
        ) {
            // Project manager
            return true
        }

        if (missionUser.isProjectManager || missionUser.permission === 2) {
            return true
        }

        if (
            [
                MISSION_PERMISSION.OBSERVER_ORG_LIMITED,
                MISSION_PERMISSION.OBSERVER_ORG,
                MISSION_PERMISSION.OBSERVER,
            ].includes(missionUser?.permission)
        ) {
            // Observers cannot invite or change permission
            return false
        }

        if ((mission.whoCanInvite || []).length) {
            return (mission.whoCanInvite || []).includes(missionUser.permission)
        }

        return true
    } catch (err) {
        // UI failed us...
        // Let the API handle it.
        return true
    }
}

/**
 * @param personId {String|Object} The user id of the person to check against
 * @param missionPeople {Object[]} Mission people
 * @return {boolean} True if user can leave the mission
 */
export function canUserDeleteMission(personId, missionPeople) {
    const missionPerson = Array.isArray(missionPeople)
        ? missionPeople.find((item) => getObjectId(item.ref) === getObjectId(personId))
        : null
    return missionPerson && missionPerson.permission === MISSION_PERMISSION.ADMIN
}

/**
 * Returns the list of mission permissions that can be removed from the provided mission person.
 * @param missionPerson {Object}
 * @return {Number[]}
 */
export function getPermissionsPersonCanRemove(missionPerson) {
    switch (missionPerson.permission) {
        case MISSION_PERMISSION.ADMIN:
            return [
                MISSION_PERMISSION.ADMIN,
                MISSION_PERMISSION.MANAGER,
                MISSION_PERMISSION.MEMBER,
                MISSION_PERMISSION.OBSERVER,
            ]
        case MISSION_PERMISSION.MANAGER:
            return [MISSION_PERMISSION.MEMBER]
        default:
            return []
    }
}

/**
 * Returns true if it's a mission observer
 * @param permission {Number}
 * @return {boolean}
 */
export function isMissionObserver(permission) {
    return [MISSION_PERMISSION.OBSERVER].includes(permission)
}

/**
 * Returns true if it's either a mission or org observer
 * @param permission {Number}
 * @return {boolean}
 */
export function isMissionObserverAny(permission) {
    return [
        MISSION_PERMISSION.OBSERVER,
        MISSION_PERMISSION.OBSERVER_ORG,
        MISSION_PERMISSION.OBSERVER_ORG_LIMITED,
    ].includes(permission)
}

/**
 * Returns true if it's an org observer
 * @param permission {Number}
 * @return {boolean}
 */
export function isMissionObserverOrg(permission) {
    return [MISSION_PERMISSION.OBSERVER_ORG, MISSION_PERMISSION.OBSERVER_ORG_LIMITED].includes(permission)
}

export function isOrgManagement(org, personId) {
    if (!org || !personId) return null

    if (!org.people) return false

    const fp = org.people.find((p) => getRefId(p) === personId)

    if (fp) return fp.permission === 1 || fp.permission === 2

    return false
}

export function isOrgObserver(org, personId) {
    if (!org || !personId) return null

    if (!org.people) return null

    const fp = org.people.find((p) => getRefId(p) === personId)

    if (fp) return fp.permission === 3

    return false
}

/**
 * Returns true if Mission has an active license that applies to all add-ons.
 * Like key licenses or org wide licenses.
 * @param mission {object}
 * @param mission.isKeyLicensed {boolean}
 * @param mission.isOrgLicensed {boolean}
 * @param mission.isLicensedActive {boolean}
 * @return {boolean}
 */
export function hasUnlimitedLicenseActive(mission) {
    return (
        get(mission, "isKeyLicensed") === true ||
        (get(mission, "isOrgLicensed") === true && get(mission, "isLicensedActive") === true)
    )
}

/**
 * Returns true if time tracking is enabled for at least one mission.
 * @param missionList {Object|Object[]} A single or multiple missions
 * @return {boolean}
 */
//deprecated
export function existsTimeTracking(missionList) {
    missionList = Array.isArray(missionList) ? missionList : [missionList]
    return (missionList || []).some(
        (mission) =>
            get(mission, "isKeyLicensed") === true ||
            get(mission, "isOrgLicensed") === true ||
            !!getMissionSubscriptionPlanItemByType("time_tracking", mission) ||
            !!getMissionPromoByType(mission, "time_tracking")
    )
}

/**
 * @param mission {object}
 * @param mission.cols {object[]}
 * @param actionItemList {object[]}
 * @param actionItemList[]._id {string}
 * @param actionItemList[].completedOn {number}
 * @param actionItemList[].colHistory {object[]}
 */
export function getKanbanColumnInsights(mission, actionItemList) {
    if (!actionItemList) {
        actionItemList = getAllActionItems(mission)
    }

    const insightList = flatMap(actionItemList, (actionItem) =>
        getKanbanActionItemColumnInsights(actionItem, mission.cols)
    )
    const insightGroupedByCol = groupBy(insightList, "movedTo")

    return Object.keys(insightGroupedByCol)
        .map((colId) => {
            try {
                const colInsightList = insightGroupedByCol[colId]
                const totalTimeInColumn = colInsightList.reduce((total, item) => total + item.durationInMillis, 0)
                const colInsightSentBackList = colInsightList.filter((item) => item.wasSentBack)
                const itemsSentBack = uniqBy(colInsightSentBackList, (item) => item.actionItemId).reduce(
                    (total, item) => total + (item.wasSentBack ? 1 : 0),
                    0
                )
                return {
                    col: colId,
                    itemsSentBack,
                    percentSentBack: parseFloat(((itemsSentBack / actionItemList.length) * 100).toFixed(2)),
                    averageTimeInCol: millisToHours(totalTimeInColumn / colInsightList.length),
                }
            } catch (err) {
                return undefined
            }
        })
        .filter((item) => !!item)
}

export function getKanbanActionItemColumnInsights(actionItem, missionCols) {
    const insights = []
    const missionColIdList = (missionCols || []).map((col) => col.id)
    const colHistoryList = (actionItem.colHistory || []).filter((item) => missionColIdList.includes(item.movedTo))
    const missionColIdOrder = missionColIdList.reduce(
        (obj, colId, i) => ({
            ...obj,
            [colId]: i,
        }),
        {}
    )

    colHistoryList.forEach((item, i) => {
        try {
            const actionItemInsightItem = {
                ...omit(item, ["_id"]),
                actionItemId: actionItem._id,
                durationInMillis: 0,
                wasSentBack: false,
            }

            if (i > 0) {
                const itemPrev = colHistoryList[i - 1]
                insights[i - 1].durationInMillis = item.movedOn - itemPrev.movedOn
                actionItemInsightItem.wasSentBack =
                    missionColIdOrder[item.movedTo] < missionColIdOrder[itemPrev.movedTo]
            }

            if (i === colHistoryList.length - 1) {
                actionItemInsightItem.durationInMillis = Math.max(
                    (actionItem.completedOn || Date.now()) - item.movedOn,
                    0
                )
            }

            insights.push(actionItemInsightItem)
        } catch (err) {}
    })

    return insights
}

export function kanbanMidMoveLogic(ait, act, std, col, isAnotherWeek, mission, app) {
    let stHour = 0
    let startOfTheWeekDate = startOfWeek(std, { weekStartsOn: 1 })

    let time = 0
    let inTime = 0
    let newEstimatedDuration = ait.estimatedDuration

    const thisWeek = isThisWeek(std, { weekStartsOn: 1 })

    time = toZonedTime(std).getHours()
    inTime = time <= 9 ? 0 : time - 9

    stHour = (std.getDay() ? std.getDay() - 1 : 6) * 8 + (inTime > 6 ? 6 : inTime)

    const whatsEndOfWeek =
        thisWeek && new Date().getDay() <= 5 && new Date().getDay() !== 0
            ? endOfDay(addDays(startOfTheWeekDate, 4))
            : subDays(endOfWeek(std, { weekStartsOn: 1 }), 2)

    const whatIsStartDate = startOfDay(thisWeek ? new Date() : std)

    let whatIsTheEndDate =
        ait.dueDate &&
        (isAfter(toZonedTime(ait.dueDate), whatIsStartDate) || isSameDay(toZonedTime(ait.dueDate), new Date()))
            ? toZonedTime(ait.dueDate)
            : whatsEndOfWeek

    if (isSaturday(new Date()) || isSunday(new Date())) {
        whatIsTheEndDate = endOfWeek(new Date(), { weekStartsOn: 1 })
    }

    let daysToEndOfAiActivePeriod = []

    try {
        daysToEndOfAiActivePeriod = eachDayOfInterval({
            start: whatIsStartDate,
            end: whatIsTheEndDate,
        })
    } catch (err) {
        // @makis BREAKS
    }

    newEstimatedDuration = daysToEndOfAiActivePeriod.length * 8
    const finalEstimate = newEstimatedDuration <= 4 ? 4 : roundToDivisor(newEstimatedDuration, 4)

    let upDateConfig = {
        startHour: ait.startHour !== null && ait.startHour >= 0 ? ait.startHour : roundToDivisor(stHour, 4),
        col: col,
        status: "open",
        estimatedDuration: !ait.estimatedDuration ? finalEstimate : ait.estimatedDuration,
        startDate: !ait.startDate ? getTimeUtc(std) : ait.startDate,
        endDate: null,
    }

    if (!ait.initialStartDate) {
        upDateConfig.initialStartDate = getTimeUtc(std)
    }

    app.actionItemUpdate(ait._id, upDateConfig, ait, null)

    if (act.status !== "open") {
        setTimeout(() => {
            app.actionUpdateX(act, { status: "open" })
        }, 50)
    }

    const colDetails = mission.cols.find((c) => c.id === col)

    if (colDetails?.people?.length) {
        const hydratedPeople = (colDetails.people || []).map((cp, i) => {
            return mission.people.find((p) => getRefId(p) === cp)
        })

        let tasksOn = []

        const allActionItems = getAllActionItems(mission)

        if (
            intersectionBy(
                (ait.people || []).map((p) => ({ _id: getRefId(p) })),
                (hydratedPeople || []).map((p) => ({ _id: getRefId(p) })),
                "_id"
            ).length === 0
        ) {
            hydratedPeople.forEach((hp, i) => {
                tasksOn.push({
                    hp: hp,
                    tasks: allActionItems.filter(
                        (ai) =>
                            ai.status === "open" &&
                            ai.startDate &&
                            ai.people.find((ap) => getRefId(ap) === getRefId(hp))
                    ).length,
                })
            })

            tasksOn.sort((a, b) => {
                return a.tasks - b.tasks
            })

            let personToAttach = tasksOn.filter((to) => !!to.hp)[0]?.hp

            const pastAttached = (ait.peopleAttached || []).map((p) => ({ ...p, ref: { _id: p.personId } }))

            let peopleWhoWorkedOnItFromThisCol = intersectionBy(pastAttached, hydratedPeople || [], "ref._id")

            if (peopleWhoWorkedOnItFromThisCol.length) {
                peopleWhoWorkedOnItFromThisCol = peopleWhoWorkedOnItFromThisCol.sort((a, b) => {
                    return new Date(b.date).getTime() - new Date(a.date).getTime()
                })

                personToAttach = hydratedPeople.find(
                    (p) => getRefId(p) === getRefId(peopleWhoWorkedOnItFromThisCol[0])._id
                )
            }
            setTimeout(() => {
                app.actionItemAddPerson(ait, getRefId(personToAttach))
            }, 200)
        }
    }
}

export const emojiList = [
    {
        emoji: "🏄‍",
        tip: "Easy breezy",
        value: 0,
    },
    {
        emoji: "👌",
        tip: "Shouldn't be a problem",
        value: 1,
    },
    {
        emoji: "🔥",
        tip: "Hard 🏋",
        value: 2,
    },
]

export const typeList = [
    {
        emoji: "💥",
        tip: "Critical! This needs to be done asap 🤯",
        value: 0,
    },
    {
        emoji: "🖐‍",
        tip: "Problem with this",
        value: 1,
    },
    {
        emoji: "🐛",
        tip: "Bug 🐝",
        value: 2,
    },
    {
        emoji: "🎁",
        tip: "New unplanned request.‍",
        value: 3,
    },
]

const missionThemes = [
    {
        backgroundImage: "url(/themes/missions/bg-39.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-1.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-31.jpg)",
        backgroundColor: "666666",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-37.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-29.jpg)",
        backgroundColor: "375791",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-8.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-3.jpg)",
        backgroundColor: "999999",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-6.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-49.jpg)",
        backgroundColor: "121212",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-9.jpg)",
        backgroundColor: "b80153",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-10.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-11.jpg)",
        backgroundColor: "444444",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-12.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-46.jpg)",
        backgroundColor: "121212",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-13.jpg)",
        backgroundColor: "0e234e",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-14.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-15.jpg)",
        backgroundColor: "192b32",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-16.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-17.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-18.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-20.jpg)",
        backgroundColor: "891f0c",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-21.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-22.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-23.jpg)",
        backgroundColor: "003366",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-24.jpg)",
        backgroundColor: "#000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-25.jpg)",
        backgroundColor: "4b59b0",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-26.jpg)",
        backgroundColor: "2a315f",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-28.jpg)",
        backgroundColor: "2bbcdd",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-30.jpg)",
        backgroundColor: "455465",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-2.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-33.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-34.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-35.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-36.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-41.jpg)",
        backgroundColor: "172334",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-42.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-43.jpg)",
        backgroundColor: "172334",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-48.jpg)",
        backgroundColor: "ffffff",
        dark: false,
    },
    {
        backgroundImage: "url(/themes/missions/bg-53.jpg)",
        backgroundColor: "000000",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-55.jpg)",
        backgroundColor: "567aa3",
        dark: true,
    },
    {
        backgroundImage: "url(/themes/missions/bg-56.jpg)",
        backgroundColor: "4a99d8",
        dark: true,
    },
]

export function getHourlyRates(planItem) {
    let myRate = 0
    let myBillRate = 0

    if (planItem.rateTime === "Hourly") {
        myRate = planItem.rate
    } else if (planItem.rateTime === "Weekly") {
        myRate = planItem.rate / 40
    } else if (planItem.rateTime === "Monthly") {
        myRate = (planItem.rate * 12) / 52 / 40
    } else if (planItem.rateTime === "Daily") {
        myRate = planItem.rate / 8
    }

    if (planItem.billUnit === "Hourly") {
        myBillRate = planItem.billRate
    } else if (planItem.billUnit === "Weekly") {
        myBillRate = planItem.billRate / 40
    } else if (planItem.billUnit === "Monthly") {
        myBillRate = (planItem.billRate * 12) / 52 / 40
    } else if (planItem.billUnit === "Daily") {
        myBillRate = planItem.billRate / 8
    }

    return {
        billRate: myBillRate,
        rate: myRate,
    }
}

export { missionThemes }

const roundToDivisor = (num, divisor) => Math.round(num / divisor) * divisor

export function getCustomRoleLabelByCustomRole(customRole) {
    switch (customRole) {
        case GT_CUSTOM_ROLES.PROJECT_MANAGER:
            return GT_CUSTOM_ROLE_LABELS.PROJECT_MANAGER
        case GT_CUSTOM_ROLES.ENGAGEMENT_EXECUTIVE_A:
            return GT_CUSTOM_ROLE_LABELS.ENGAGEMENT_EXECUTIVE_A
        case GT_CUSTOM_ROLES.ENGAGEMENT_EXECUTIVE_B:
            return GT_CUSTOM_ROLE_LABELS.ENGAGEMENT_EXECUTIVE_B
        case GT_CUSTOM_ROLES.OFFER_PROCEDURE_EMPLOYEE:
            return GT_CUSTOM_ROLE_LABELS.OFFER_PROCEDURE_EMPLOYEE
    }
}

export function canChangeCustomRole(mission, missionPerson, orgPerson) {
    if (!missionPerson || !orgPerson) {
        return false
    }
    return (
        mission?.createdFromGtCrm === true &&
        (missionPerson?.permission === MISSION_PERMISSION.ADMIN ||
            orgPerson?.permission === ORG_PERMISSION.ADMIN ||
            orgPerson.canApproveRoles ||
            missionPerson.customRole?.includes("Executive"))
    )
}
